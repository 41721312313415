import {
  defaultChainId,
  ulsTokenEthereumUniswapV2PairAddress as ethereumAddress,
  ulsTokenGoerliUniswapV2PairAddress as goerliAddress,
} from '../configs';
import { UniswapV2PairAbi as abi } from '../abis';



export default function getUlsTokenUniswapV2PairContractData() {
  let address = null;
  switch (defaultChainId) {
    case 1: address = ethereumAddress; break;
    case 5: address = goerliAddress; break;
    default: break;
  }

  return {
    address,
    abi,
    chainId: defaultChainId,
  };
}
