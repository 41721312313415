import TokenDarkImage from "../../assets/images/icons/token-dark.svg";
import { ReactComponent as Social1 } from "../../assets/images/icons/social-1.svg";
import { ReactComponent as SocialDiscord } from "../../assets/images/icons/social-discord.svg";
import { ReactComponent as Social2 } from "../../assets/images/icons/social-2.svg";
//import { ReactComponent as Social3 } from "../../assets/images/icons/social-3.svg";
import { ReactComponent as Social4 } from "../../assets/images/icons/social-4.svg";
import { ReactComponent as Social5 } from "../../assets/images/icons/social-5.svg";
import { ReactComponent as Social6 } from "../../assets/images/icons/social-6.svg";
import SocialDexTools from "../../assets/images/icons/dextools_logo.png";
import SocialYoutube from "../../assets/images/icons/youtube.png";


import { Link } from "react-router-dom";
import { routerPaths } from '../../router/helper';
import {
  whitepaperUrl,
  ulsTokenEtherscanUrl,
  ulsStakingEtherscanUrl,
  ulsTokenAuditUrl,
  ulsDiscordUrl,
  ulsCoinmarketcapUrl,
  ulsCoingeckoUrl,
  //ulsCryptorankUrl,
  ulsDexToolsUrl,
  ulsDropstabUrl,
  ulsTelegramUrl,
  ulsTwitterUrl,
  ulsYoutubeUrl,
} from '../../configs';
import { NumericFormat } from 'react-number-format';
import { getExternalLinkProps } from '../../utils';

import { useSelector } from 'react-redux';
import * as S from '../../store/selectors';



export default function Footer() {
  const {
    totalSupply,
    totalStaked,
    circulatingSupply,
    priceUsd, marketCap,
  } = useSelector((state) => S.onChainData.getGlobalData(state));

  return (
    <div className="footer position-relative">
      <div className="block-color-11">
        <svg xmlns="http://www.w3.org/2000/svg" width="208" height="208" viewBox="0 0 208 208" fill="none">
          <rect width="104" height="104" transform="matrix(0 1 1 0 104 0)" fill="white" fillOpacity="0.03" />
          <rect width="104" height="104" transform="matrix(0 1 1 0 0 104)" fill="white" fillOpacity="0.03" />
        </svg>
      </div>
      <div className="block-color-12">
        <svg xmlns="http://www.w3.org/2000/svg" width="177" height="177" viewBox="0 0 177 177" fill="none">
          <rect x="88.5" y="177" width="88.5" height="88.5" transform="rotate(-90 88.5 177)" fill="white" fillOpacity="0.03" />
          <rect y="88.5" width="88.5" height="88.5" transform="rotate(-90 0 88.5)" fill="white" fillOpacity="0.03" />
        </svg>
      </div>
      <div className="block-color-13">
        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
          <rect x="32.5" y="65" width="32.5" height="32.5" transform="rotate(-90 32.5 65)" fill="white" fillOpacity="0.03" />
          <rect y="32.5" width="32.5" height="32.5" transform="rotate(-90 0 32.5)" fill="white" fillOpacity="0.03" />
        </svg>
      </div>

      <div className=" container">
        <div className="row">
          <div className="col-md-4 col-6">
            <div className="footer-title">
              Docs
            </div>
            <ul className="footer-list">
              <li>
                <a
                  title='Whitepaper'
                  {...getExternalLinkProps()}
                  href={whitepaperUrl}
                >Whitepaper</a>
              </li>
              <li>
                <Link title='Roadmap' to={routerPaths.docsRoadmapPage}>Roadmap</Link>
              </li>
              <li>
                <Link title='Tokenomics' to={routerPaths.docsTokenomicsPage}>Tokenomics</Link>
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-6">
            <div className="footer-title">
              Smart Contracts
            </div>
            <ul className="footer-list">
              <li>
                <a
                  title='Token Smart Contract'
                  {...getExternalLinkProps()}
                  href={ulsTokenEtherscanUrl}
                >Token Smart Contract</a>
              </li>
              <li>
                <a
                  title='Staking Smart Contract'
                  {...getExternalLinkProps()}
                  href={ulsStakingEtherscanUrl}
                >Staking Smart Contract</a>
              </li>
              <li>
                <a
                  title='Security Audit'
                  {...getExternalLinkProps()}
                  href={ulsTokenAuditUrl}
                >Security Audit</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mt-36-m">
            <div className="footer-info">
              <img src={TokenDarkImage} alt="" />
              <div className="footer-price">
                ULS
                <span>
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    fixedDecimalScale={true} decimalScale={4}
                    value={priceUsd}
                    prefix='$'
                  />
                </span>
              </div>
            </div>

            <div className="footer-items">
              <div className="footer-item d-flex justify-content-between">
                <span className="text-gray">
                  Total Supply:
                </span>
                <span className="text-bold">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    fixedDecimalScale={true} decimalScale={0}
                    value={totalSupply}
                    suffix=' ULS'
                  />
                </span>
              </div>

              <div className="footer-item d-flex justify-content-between">
                <span className="text-gray">
                  Circulating Supply:
                </span>
                <span className="text-bold">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    fixedDecimalScale={true} decimalScale={0}
                    value={circulatingSupply}
                    suffix=' ULS'
                  />
                </span>
              </div>

              <div className="footer-item d-flex justify-content-between">
                <span className="text-gray">
                  Total Staked:
                </span>
                <span className="text-bold">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    fixedDecimalScale={true} decimalScale={0}
                    value={totalStaked}
                    suffix=' ULS'
                  />
                </span>
              </div>

              <div className="footer-item d-flex justify-content-between">
                <span className="text-gray">
                  Market Cap:
                </span>
                <span className="text-bold">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    fixedDecimalScale={true} decimalScale={0}
                    value={marketCap}
                    prefix='$'
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col">
              <div className="copyright">© UNITS LIMITED SUPPLY</div>
            </div>
            <div className="col-md-6 col">
              <div className="socials d-flex align-items-center justify-content-end gap-16 gap-8-m">
                <a
                  className="social"
                  title='DEXTools'
                  {...getExternalLinkProps()}
                  href={ulsDexToolsUrl}
                >
                  <img src={SocialDexTools} />
                </a>
                <a className="social" title='CoinGecko'
                  {...getExternalLinkProps()}
                  href={ulsCoingeckoUrl}
                >
                  <Social2 />
                </a>
                <a
                  className="social"
                  title='CoinMarketCap'
                  {...getExternalLinkProps()}
                  href={ulsCoinmarketcapUrl}
                >
                  <Social1 />
                </a>
                {/*<a
                   className="social"
                   title='CryptoRank'
                  {...getExternalLinkProps()}
                  href={ulsCryptorankUrl}
                  >
                  <Social3 />
                </a>*/}
                <a
                  className="social"
                  title='DropStab'
                  {...getExternalLinkProps()}
                  href={ulsDropstabUrl}
                >
                  <Social4 />
                </a>

                <a
                  className="social"
                  title='YouTube'
                  {...getExternalLinkProps()}
                  href={ulsYoutubeUrl}
                >
                  <img src={SocialYoutube} />
                </a>
                <a
                  className="social"
                  title='Discord'
                  {...getExternalLinkProps()}
                  href={ulsDiscordUrl}
                >
                  <SocialDiscord />
                </a>
                <a
                  className="social"
                  title='Telegram'
                  {...getExternalLinkProps()}
                  href={ulsTelegramUrl}
                >
                  <Social5 />
                </a>
                <a
                  className="social"
                  title='Twitter'
                  {...getExternalLinkProps()}
                  href={ulsTwitterUrl}
                >
                  <Social6 />
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-32">
            <div>
              <a
              //href="https://alchemy.com/?r=3ef6fb82dd5b3369"
              //{...getExternalLinkProps()} 
              >
                <img
                  onClick={() => window?.logBadgeClick()}
                  id="badge-button"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '240px', height: '53px',
                  }}
                  src="https://static.alchemyapi.io/images/marketing/badge.png"
                  alt="Alchemy Supercharged"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
