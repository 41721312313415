import {
  decimals,
} from '../configs';
import getHalvings from './getHalvings';
import BN from 'bignumber.js';


BN.set({
  DECIMAL_PLACES: decimals,
  ROUNDING_MODE: BN.ROUND_DOWN,
});


export default function getStakingRate(timeMs = 0) {
  const endTimeMs = timeMs + 24 * 60 * 60 * 1000;

  const { startTimeMs, halvings, } = getHalvings();
  if (timeMs <= startTimeMs) {
    //return '0.0';
    return '6467.615821912046400000';
  }

  let distributedRewards = BN('0.0');


  for (let i in halvings) {
    if (timeMs >= halvings[i].startTimeMs || endTimeMs >= halvings[i].startTimeMs) {
      let startTimeMs = timeMs;
      if (startTimeMs < halvings[i].startTimeMs) {
        startTimeMs = halvings[i].startTimeMs;
      }

      let stopTimeMs = endTimeMs;
      if (halvings.length <= (i + 1) && endTimeMs >= halvings[i + 1].startTimeMs) {
        stopTimeMs = halvings[i + 1].startTimeMs;
      }
      const seconds = parseInt((stopTimeMs - startTimeMs) / 1000);
      const rewards = halvings[i].ratePerSec.multipliedBy(seconds);
      distributedRewards = distributedRewards.plus(rewards);
    }
  }

  return distributedRewards.toString();
}
