import { useState, useEffect } from "react";

/*import image1 from "../../assets/images/cards/image-1.jpg";
import image2 from "../../assets/images/cards/image-2.jpg";
import image3 from "../../assets/images/cards/image-3.jpg";
import image4 from "../../assets/images/cards/image-4.jpg";
import image5 from "../../assets/images/cards/image-5.jpg";
import image6 from "../../assets/images/cards/image-6.jpg";*/

import uniswapImage from "../../assets/images/cards/uniswap.jpeg";
import arbitrumImage from "../../assets/images/cards/arbitrum.png";
import SocialDexTools from "../../assets/images/icons/dextools_logo.png";
import SocialTeamFinance from "../../assets/images/icons/team_finance.png";
import SocialUncx from "../../assets/images/icons/uncx-logo-solo-brand-logo.43e2f4cd.svg";
import Social1inch from "../../assets/images/icons/1inch.svg";
import SocialWc from "../../assets/images/icons/wc.png";
import SocialKyberswap from "../../assets/images/icons/kyberswap.ico";
import SocialDexscreener from "../../assets/images/icons/dexscreener.png";
import SocialCamelot from "../../assets/images/icons/camelot.ico";
import SocialPancakeswap from "../../assets/images/icons/pancakeswap.png";
import SocialOkx from "../../assets/images/icons/okx.png";
import SocialTangem from "../../assets/images/icons/tangem.png";
import SocialMetamask from "../../assets/images/icons/metamask.png";
import SocialRainbow from "../../assets/images/icons/rainbow.png";


import {
  ulsTokenUniswapUrl, ulsDexToolsUrl, ulsTeamFinanceUrl,
  ulsUncxUrl, uls1inchUrl, ulsWalletconnectUrl,
  ulsKyberswapUrl, ulsDexscreenerUrl, ulsCamelotExchangeUrl, ulsPancakeswapUrl,
  uls1inchWalletUrl, ulsOkxWalletUrl, ulsTangemWalletUrl, ulsMetamaskWalletUrl, ulsUniswapWalletUrl, ulsRainbowWalletUrl
} from "../../configs";
import { getExternalLinkProps } from '../../utils';



const buttons = [
  { name: "All", value: "all" },
  { name: "Exchanges", value: "exchanges" },
  { name: "Crosschain Bridges", value: "crosschain" },
  { name: "Wallets", value: "wallets" },
];


const dataCards = [
  {
    category: 'exchanges',
    image: SocialDexTools,
    title: 'DEX Tools',
    text: 'DEX Tools, the gateway to DEFI',
    link: ulsDexToolsUrl,
  },
  {
    category: 'crosschain',
    image: arbitrumImage,
    title: 'Arbitrum Bridge',
    text: 'Bridge funds using the Arbitrum Bridge',
    link: 'https://bridge.arbitrum.io/?l2ChainId=42161',
  },
  {
    category: 'exchanges',
    image: uniswapImage,
    title: 'Uniswap',
    text: 'Decentralized crypto trading protocol',
    link: ulsTokenUniswapUrl,
  },
  {
    category: 'wallets',
    image: SocialTeamFinance,
    title: 'Team.Finace',
    text: 'Manage all your token needs in one place',
    link: ulsTeamFinanceUrl,
  },
  {
    category: 'wallets',
    image: SocialUncx,
    title: 'UNCX.NETWORK',
    text: 'Secure DeFi Infrastructure',
    link: ulsUncxUrl,
  }, {
    category: 'exchanges',
    image: Social1inch,
    title: '1INCH DEX',
    text: `DeFi / DEX aggregator`,
    link: uls1inchUrl,
  },
  {
    category: 'wallets',
    image: SocialWc,
    title: 'Wallet Connect',
    text: `WalletConnect provides developer toolkits`,
    link: ulsWalletconnectUrl,
  },

  {
    category: 'exchanges',
    image: SocialKyberswap,
    title: 'Kyber Swap',
    text: 'KyberSwap is a multi-chain aggregator and DeFi hub',
    link: ulsKyberswapUrl,
  },
  {
    category: 'exchanges',
    image: SocialDexscreener,
    title: 'DEX Screener',
    text: 'Realtime price charts and trading history on DEX',
    link: ulsDexscreenerUrl,
  },
  {
    category: 'exchanges',
    image: SocialCamelot,
    title: 'Camelot DEX',
    text: 'The Arbitrum native DEX',
    link: ulsCamelotExchangeUrl,
  },
  {
    category: 'exchanges',
    image: SocialPancakeswap,
    title: 'Pancake Swap',
    text: 'The most popular AMM DEX Exchange',
    link: ulsPancakeswapUrl,
  },

  {
    category: 'wallets',
    image: Social1inch,
    title: '1inch Wallet',
    text: `Crypto Wallet`,
    link: uls1inchWalletUrl,
  },
  {
    category: 'wallets',
    image: SocialOkx,
    title: 'OKX Wallet',
    text: `Crypto Wallet`,
    link: ulsOkxWalletUrl,
  },
  {
    category: 'wallets',
    image: SocialTangem,
    title: 'Tangem Wallet',
    text: `Hardware Crypto Wallet `,
    link: ulsTangemWalletUrl,
  },
  {
    category: 'wallets',
    image: SocialMetamask,
    title: 'Metamask Wallet',
    text: `Crypto Wallet`,
    link: ulsMetamaskWalletUrl,
  },
  {
    category: 'wallets',
    image: uniswapImage,
    title: 'Uniswap Wallet',
    text: `Crypto Wallet`,
    link: ulsUniswapWalletUrl,
  },
  {
    category: 'wallets',
    image: SocialRainbow,
    title: 'Rainbow Wallet',
    text: `Crypto Wallet`,
    link: ulsRainbowWalletUrl,
  },
];

export function getinfo() {
  const infoList = dataCards;
  return infoList;
}

export function filterinfo(pokeType) {
  let filtredinfo = getinfo().filter(type => type.category === pokeType);
  return filtredinfo;
}

export default function Cards() {
  const [filtredinfo, setFiltredinfo] = useState(null);
  useEffect(() => {
    setFiltredinfo(getinfo());
  }, []);

  function handleinfo(e) {
    let typeinfo = e.target.value;

    typeinfo !== "all"
      ? setFiltredinfo(filterinfo(typeinfo))
      : setFiltredinfo(getinfo());

    const currTarget = e.currentTarget;

    document.querySelectorAll('.filter-item').forEach(btn =>
      btn.classList.remove('is-active'))
    currTarget.classList.add('is-active');
  }


  return (
    <>
      <div className="section section-xs filter">
        <div className="row gx-3">
          {buttons &&
            buttons.map((type, index) => (
              <div key={index} className="col-md-3">
                <button
                  key={index}
                  className="filter-item font-minecraft"
                  value={type.value}
                  onClick={handleinfo}
                >
                  {type.name}
                </button>
              </div>
            ))}
        </div>
      </div>

      <div className="cards">
        <div className="row g-3">
          {filtredinfo &&
            filtredinfo.map(type => (
              <div key={type.id} className="col-md-4 ">
                <div className="cards-item">
                  <div className="cards-item-content">
                    <div className="cards-item-title">
                      {type.title}
                    </div>

                    <div className="cards-item-text">
                      {type.text}
                    </div>
                    <a
                      className="cards-item-link font-minecraft"
                      {...getExternalLinkProps()}
                      href={type.link}
                    >Link</a>
                  </div>

                  <div className="cards-item-image ">
                    <img src={type.image} alt="" width={type.title === 'OKX Wallet' ? '120px' : undefined} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
