import * as R from 'ramda';
import * as AT from './actionTypes';



const INITIAL_STATE = {
  timeMs: Date.now(),
};


export default function clockReducer(state = INITIAL_STATE, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return INITIAL_STATE;
    }

    case AT.SET_TIME_MS: {
      return {
        timeMs: payload,
      };
    }

    default:
      return state;
  }
}
