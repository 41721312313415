import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Navigations from "../../../components/Navigations";
import { NavigationLinks } from "../index";

import Right from '../../../assets/images/right.png'
import Left from '../../../assets/images/left.png'

import {
  ulsStakingEthereumAddress, ulsStakingEtherscanUrl,
  ulsStakingSupplierEthereumAddress, ulsStakingSupplierEtherscanUrl,
  ulsStakingScheduleEthereumAddress, ulsStakingScheduleEtherscanUrl,
  ulsTokenStakingDetailedCalculationUrl,
} from '../../../configs';
import { getExternalLinkProps } from '../../../utils';



export default function PageDocsStaking() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section section-xs filter">
          <NavigationLinks />
        </div>


        <div className="section mt-44 position-relative">
          <div className="title-neon mb-24">
            Staking
          </div>
          <div className="text-md text-p block-4">
            <p>
              The staking smart contract is implemented in a single Ethereum mainnet without the possibility of updating. There are no new blockchain networks for the staking function. And it won't be possible in the future. The staking logic is implemented using three smart contracts designed for 80 years of work with an accurate calculation of rewards up to 1 second.
            </p>

            <a
              className="d-inline-block"
              title={'ULS Token Staking'}
              {...getExternalLinkProps()}
              href={ulsStakingEtherscanUrl}
            >
              {ulsStakingEthereumAddress}
            </a>
            <br />

            <a
              className="d-inline-block"
              title={'ULS Token Staking Supplier'}
              {...getExternalLinkProps()}
              href={ulsStakingSupplierEtherscanUrl}
            >
              {ulsStakingSupplierEthereumAddress}
            </a>
            <br />

            <a
              className="d-inline-block"
              title={'ULS Token Staking Schedule'}
              {...getExternalLinkProps()}
              href={ulsStakingScheduleEtherscanUrl}
            >
              {ulsStakingScheduleEthereumAddress}
            </a>
          </div>

          <div className="block-color-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="242" height="243" viewBox="0 0 242 243" fill="none">
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 120.875 0.375)" fill="white" fillOpacity="0.03" />
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 0 121.25)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>


          <div className="block-color-5">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
              <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
            </svg>
          </div>
        </div>

        <div className="text-md text-p">
          <p>
            You can use the staking function on the official ULS website in the «Staking section» This section implements functions such as:
          </p>
          <ul>
            <li>Stake ULS</li>
            <li>Restake ULS</li>
            <li>Claim ULS</li>
            <li>Personal on-chain information</li>
            <li>General on-chain information</li>
          </ul>

          <p>
            You can also use all available functions with Etherscan:
          </p>
          <p>
            <a
              className="d-inline-block"
              title={'ULS Token Staking'}
              {...getExternalLinkProps()}
              href={`${ulsStakingEtherscanUrl}#writeContract`}
            >
              {ulsStakingEtherscanUrl}#writeContract
            </a>
          </p>

          <p>
            Simple math: Total Staking Rewards = 6,180,339.88 ULS = 61.80 % from the Total supply.
          </p>
          <p>
            This is the number of awards that will be given out for 80 years through staking, decreasing every year according to the golden ratio. From 6,180,339.88 - 38.19% per year is deducted and the reward for 1 year is calculated. By allocating a small part of the golden ratio, we receive a limited number of awards per year, which is getting smaller and smaller every year. 6,180,339.88 - 38.19%-38.19%-38.19%-38.19%........
          </p>
          <p>
            And so on until the last token. Thus, we get an alternative option of mining and halving.
          </p>
          <p>
            It will start at the date and time (01:01:2024.00:00:00) UTC+0
          </p>
          <p>
            Below is a schedule of awards, that is, the maximum number of awards in ULS Tokens for each year of work:
          </p>
        </div>
        <div className="outer position-relative mb-40">
          <div className="years-nav years-nav-left for-devices">
            <img src={Left} alt="Left" title="Left" />
          </div>
          <div className="years-nav years-nav-right for-devices">
            <img src={Right} alt="Right" title="Right" />
          </div>

          <div className="section-md years-outer   mt-20 text-gray">
            <div className="row years position-relative">
              <div className="col-4">
                <ul>
                  <li>Year: 1, Rewards: 2,360,679.774997896936000000</li>
                  <li>Year: 2, Rewards: 1,458,980.337503154516480000</li>
                  <li>Year: 3, Rewards: 901,699.437494742356448000</li>
                  <li>Year: 4, Rewards: 557,280.900008412096960000</li>
                  <li>Year: 5, Rewards: 344,418.537486330227952000</li>
                  <li>Year: 6, Rewards: 212,862.362522081837472000</li>
                  <li>Year: 7, Rewards: 131,556.174964248358944000</li>
                  <li>Year: 8, Rewards: 81,306.187557833446992000</li>
                  <li>Year: 9, Rewards: 50,249.987406414848880000</li>
                  <li>Year: 10, Rewards: 31,056.200151418535040000</li>
                  <li>Year: 11, Rewards: 19,193.787254996282304000</li>
                  <li>Year: 12, Rewards: 11,862.412896422221200000</li>
                  <li>Year: 13, Rewards: 7,331.374358573998032000</li>
                  <li>Year: 14, Rewards: 4,531.038537848160096000</li>
                  <li>Year: 15, Rewards: 2,800.335820725774864000</li>
                  <li>Year: 16, Rewards: 1,730.702717122353696000</li>
                  <li>Year: 17, Rewards: 1,069.633103603389632000</li>
                  <li>Year: 18, Rewards: 661.069613518900992000</li>
                  <li>Year: 19, Rewards: 408.563490084425568000</li>
                  <li>Year: 20, Rewards: 252.506123434443888000</li>
                  <li>Year: 21, Rewards: 156.057366649950144000</li>
                  <li>Year: 22, Rewards: 96.448756784462208000</li>
                  <li>Year: 23, Rewards: 59.608609865456400000</li>
                  <li>Year: 24, Rewards: 36.840146918974272000</li>
                  <li>Year: 25, Rewards: 22.768462946450592000</li>
                  <li>Year: 26, Rewards: 14.071683972492144000</li>
                  <li>Year: 27, Rewards: 8.696778973926912000</li>
                  <li>Year: 28, Rewards: 5.374904998502160000</li>
                  <li>Year: 29, Rewards: 3.321873975361680000</li>
                  <li>Year: 30, Rewards: 2.053031023108944000</li>
                </ul>
              </div>

              <div className="col-4">
                <ul>
                  <li>Year: 31, Rewards: 1.268842952221200000</li>
                  <li>Year: 32, Rewards: 0.784188070856208000</li>
                  <li>Year: 33, Rewards: 0.484654881333456000</li>
                  <li>Year: 34, Rewards: 0.299533189459680000</li>
                  <li>Year: 35, Rewards: 0.185121691842240000</li>
                  <li>Year: 36, Rewards: 0.114411497585904000</li>
                  <li>Year: 37, Rewards: 0.070710194193264000</li>
                  <li>Year: 38, Rewards: 0.043701303361104000</li>
                  <li>Year: 39, Rewards: 0.027008890800624000</li>
                  <li>Year: 40, Rewards: 0.016692412497408000</li>
                  <li>Year: 41, Rewards: 0.010316478271680000</li>
                  <li>Year: 42, Rewards: 0.006375934194192000</li>
                  <li>Year: 43, Rewards: 0.003940544014416000</li>
                  <li>Year: 44, Rewards: 0.002435390116704000</li>
                  <li>Year: 45, Rewards: 0.001505153866176000</li>
                  <li>Year: 46, Rewards: 0.000930236218992000</li>
                  <li>Year: 47, Rewards: 0.000574917584112000</li>
                  <li>Year: 48, Rewards: 0.000355318603344000</li>
                  <li>Year: 49, Rewards: 0.000219598949232000</li>
                  <li>Year: 50, Rewards: 0.000135719591040000</li>
                  <li>Year: 51, Rewards: 0.000083879295120000</li>
                  <li>Year: 52, Rewards: 0.000051840232848000</li>
                  <li>Year: 53, Rewards: 0.000032038999200000</li>
                  <li>Year: 54, Rewards: 0.000019801170576000</li>
                  <li>Year: 55, Rewards: 0.000012237765552000</li>
                  <li>Year: 56, Rewards: 0.000007563341952000</li>
                  <li>Year: 57, Rewards: 0.000004674392064000</li>
                  <li>Year: 58, Rewards: 0.000002888918352000</li>
                  <li>Year: 59, Rewards: 0.000001785442176000</li>
                  <li>Year: 60, Rewards: 0.000001103444640000</li>
                </ul>
              </div>

              <div className="col-4">
                <ul>
                  <li>Year: 61, Rewards: 0.000000681966000000</li>
                  <li>Year: 62, Rewards: 0.000000421447104000</li>
                  <li>Year: 63, Rewards: 0.000000260455824000</li>
                  <li>Year: 64, Rewards: 0.000000160959744000</li>
                  <li>Year: 65, Rewards: 0.000000099464544000</li>
                  <li>Year: 66, Rewards: 0.000000061463664000</li>
                  <li>Year: 67, Rewards: 0.000000037969344000</li>
                  <li>Year: 68, Rewards: 0.000000023462784000</li>
                  <li>Year: 69, Rewards: 0.000000014475024000</li>
                  <li>Year: 70, Rewards: 0.000000008924688000</li>
                  <li>Year: 71, Rewards: 0.000000005487264000</li>
                  <li>Year: 72, Rewards: 0.000000003374352000</li>
                  <li>Year: 73, Rewards: 0.000000002081376000</li>
                  <li>Year: 74, Rewards: 0.000000001261440000</li>
                  <li>Year: 75, Rewards: 0.000000000756864000</li>
                  <li>Year: 76, Rewards: 0.000000000441504000</li>
                  <li>Year: 77, Rewards: 0.000000000252288000</li>
                  <li>Year: 78, Rewards: 0.000000000126144000</li>
                  <li>Year: 79, Rewards: 0.000000000063072000</li>
                  <li>Year: 80, Rewards: 0.000000000031536000</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="text-md text-p">
          <p>
            <div className="text-white title-md">
              A more detailed calculation is available at the <a
                title={'ULS Token Staking detailed calculation'}
                {...getExternalLinkProps()}
                href={ulsTokenStakingDetailedCalculationUrl}
              >link</a>.
            </div>
          </p>
          <p>
            <div className="text-white title-md">
              How is the staking reward issued? <br />
              Who has staked more, has more reward!
            </div>
          </p>
          <p>
            For example, we round up the numbers: For example, there are only 1,000 ULS Tokens in the hands of holders.
          </p>
          <ul>
            <li>Bob has 100 ULS</li>
            <li>Nat has 100 ULS</li>
            <li>And you have 100 ULS</li>
          </ul>

          <p>
            The remaining 700 tokens are in circulation.
          </p>
          <p>
            Bob, Nat and you decided to stake all your tokens at 100 ULS each.
          </p>
          <p>
            The maximum reward in a given period of 1 year = 2,360,679.77 ULS, where for 1 day the reward = 6,467.61 ULS.
          </p>
          <ul>
            <li>Rewards for 1 day of staking = 6,467.61ULS = 100%</li>
            <li>Bob 33,33% = 2,155.85 ULS</li>
            <li>Nat 33,33% = 2,155.85 ULS</li>
            <li>You are 33,33% = 2,155.85 ULS</li>
          </ul>
          <p>
            If a new participant, Jan, arrives and he has 250 ULS Tokens that he wants to stake, the math changes proportionally. The one who has more tokens for a stake gets a larger share of the reward.
          </p>

          <ul>
            <li>Bob has 100 ULS</li>
            <li>Nat has 100 ULS</li>
            <li>You have 100 ULS</li>
            <li>Jan has 250 ULS</li>
          </ul>
          <p>
            Now there are 550 ULS Tokens in the staking smart contract. Reward of 1 year = 2,360,679.77 ULS 1 day reward = 6,467.61 ULS
          </p>
          <p>
            Distribution of awards after the arrival of Jan: 6,467.61 ULS = 100%
          </p>

          <ul>
            <li>Bob 18.18% = 1,175.93 ULS</li>
            <li>Nat 18.18% = 1,175.93 ULS</li>
            <li>You are 18.18% = 1,175.93</li>
            <li>ULS Jan 45.45% = 2,939.82 ULS</li>
          </ul>

          <p>
            <div className="text-white title-md">
              All awards are given in proportion to the number of participants. <br />
              The task of the participants is to get all the ULS Tokens.
            </div>
          </p>
        </div>

        <Footer />
      </div>
    </main>
  );
}
