import {
  ulsStartStakingDate,
  k2, decimals,
} from '../configs';
import getHalvings from './getHalvings';
import BN from 'bignumber.js';


BN.set({
  DECIMAL_PLACES: decimals,
  ROUNDING_MODE: BN.ROUND_DOWN,
});


export default function getDistributedRewards(timeMs = 0) {
  let distributedRewards = BN('0.0');

  const { startTimeMs, halvings, } = getHalvings();
  if (timeMs <= startTimeMs) {
    return distributedRewards.toString();
  }


  for (let i in halvings) {
    let startTimeMs = halvings[i].startTimeMs;
    if (timeMs > halvings[i].startTimeMs) {
      let stopTimeMs = timeMs;
      if (halvings.length <= (i + 1) && timeMs >= halvings[i + 1].startTimeMs) {
        stopTimeMs = halvings[i + 1].startTimeMs;
      }
      const seconds = parseInt((stopTimeMs - startTimeMs) / 1000);
      const rewards = halvings[i].ratePerSec.multipliedBy(seconds);
      distributedRewards = distributedRewards.plus(rewards);
    }
  }

  return distributedRewards.toString();
}
