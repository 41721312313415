import { Routes, Route, /*Navigate,*/ } from 'react-router-dom';
import { routerPaths } from './helper';

import Home from '../pages/Home';
import Staking from "../pages/Docs/pages/Staking";
import Integrations from "../pages/Integrations";
import Faq from "../pages/Faq";
//import Docs from "../pages/Docs";
import Intro from "../pages/Docs/pages/Intro";
import UlsToken from "../pages/Docs/pages/UlsToken";
import Roadmap from "../pages/Docs/pages/Roadmap";
import Tokenomics from "../pages/Docs/pages/Tokenomics";
import Links from "../pages/Docs/pages/Links";



export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
      </Route>
      <Route path="/:locale">
        <Route index element={<Home />} />
      </Route>

      <Route path="*" element={<Home />} />
      <Route path={routerPaths.integrationsPage} element={<Integrations />} />
      <Route path={routerPaths.faqPage} element={<Faq />} />

      <Route path={routerPaths.docsPage} element={<Intro />} />
      <Route path={routerPaths.docsIntroPage} element={<Intro />} />
      <Route path={routerPaths.docsUslTokenPage} element={<UlsToken />} />
      <Route path={routerPaths.docsStakingPage} element={<Staking />} />
      <Route path={routerPaths.docsRoadmapPage} element={<Roadmap />} />
      <Route path={routerPaths.docsTokenomicsPage} element={<Tokenomics />} />
      <Route path={routerPaths.docsLinksPage} element={<Links />} />

      {/*<Route path='*' element={<Navigate to={routerPaths.homePage} />} />*/}
    </Routes>
  );
}
