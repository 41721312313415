import { getIn } from 'immutable';



const root = 'onChainData';

export function getGlobalData(state) {
  return {
    timeMs: getIn(state, [root, 'timeMs'], 0),
    blockNumber: getIn(state, [root, 'blockNumber'], 0),

    totalSupply: getIn(state, [root, 'totalSupply'], '0.0'),
    totalStaked: getIn(state, [root, 'totalStaked'], '0.0'),
    historicalRewardRate: getIn(state, [root, 'historicalRewardRate'], '0'),

    circulatingSupply: getIn(state, [root, 'circulatingSupply'], '0.0'),

    priceEth: getIn(state, [root, 'priceEth'], '0.0'),
    priceUsd: getIn(state, [root, 'priceUsd'], '0.0'),
    marketCap: getIn(state, [root, 'marketCap'], '0.0'),

    stakingRate: getIn(state, [root, 'stakingRate'], '0.0'),
  };
}

export function getPersonalData(state) {
  return {
    timeMs: getIn(state, [root, 'timeMs'], 0),
    blockNumber: getIn(state, [root, 'blockNumber'], 0),
    address: getIn(state, [root, 'address'], null),

    balance: getIn(state, [root, 'balance'], '0.0'),
    staked: getIn(state, [root, 'staked'], '0.0'),
    initialRewardRate: getIn(state, [root, 'initialRewardRate'], '0'),
    rewards: getIn(state, [root, 'rewards'], '0.0'),
    claimedRewards: getIn(state, [root, 'claimedRewards'], '0.0'),
    unclaimedRewards: getIn(state, [root, 'unclaimedRewards'], '0.0'),
  };
}

export function getBlockNumber(state) {
  return getIn(state, [root, 'blockNumber'], 0);
}


