import { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';



const dataEl = [
  { name: 'Team:', value: 1, },
  { name: 'Airdrop:', value: 1, },
  { name: 'Token sale:', value: 36.2, },
  { name: 'Staking Rewards:', value: 61.8, },
];

const COLORS = ['#FCFF59', '#59C3FF', '#A259FF', '#59FF9C'];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${dataEl[0].name}${index}`}
    </text>
  );
};

let renderLabel = function (entry) {
  return `${entry.name} ${entry.value}%`;
}


export default class TokenomicsChart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer className="tokenomics-chart" height={432}>
        <PieChart width={800} height={400}>
          <Pie
            data={dataEl}
            cx="50%"
            cy="50%"
            innerRadius={130}
            outerRadius={160}
            stroke="black"
            paddingAngle={2}
            dataKey="value"
            label={renderLabel}
          >
            {dataEl.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
