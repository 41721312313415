import * as R from 'ramda';
import { setIn } from 'immutable';
import * as AT from './actionTypes';



const INITIAL_STATE = {
  // GLOBAL
  timeMs: 0,
  blockNumber: 0,

  totalSupply: '10000000.0',
  totalStaked: '0.0',
  historicalRewardRate: '0',

  circulatingSupply: '0.0',

  priceEth: '0.0',
  priceUsd: '0.0',
  marketCap: '0.0',

  stakingRate: '0.0',


  // PERSONAL
  address: null,
  balance: '0.0',
  staked: '0.0',
  initialRewardRate: '0',
  rewards: '0.0',
  claimedRewards: '0.0',
  unclaimedRewards: '0.0',
};


export default function onChainDataReducer(state = INITIAL_STATE, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return INITIAL_STATE;
    }

    case AT.SET_GLOBAL_DATA: {
      let temp = setIn(state, ['timeMs'], payload?.timeMs || Date.now());

      temp = setIn(temp, ['totalSupply'], payload?.totalSupply || '0.0');
      temp = setIn(temp, ['totalStaked'], payload?.totalStaked || '0.0');
      temp = setIn(temp, ['historicalRewardRate'], payload?.historicalRewardRate || '0');

      temp = setIn(temp, ['priceEth'], payload?.priceEth || '0.0');
      temp = setIn(temp, ['priceUsd'], payload?.priceUsd || '0.0');
      temp = setIn(temp, ['marketCap'], payload?.marketCap || '0.0');
      return temp;
    }

    case AT.SET_CIRCULATING_SUPPLY: {
      return setIn(state, ['circulatingSupply'], payload || '0.0');
    }

    case AT.SET_STAKING_RATE: {
      return setIn(state, ['stakingRate'], payload || '0.0');
    }

    case AT.SET_BLOCK_NUMBER: {
      return setIn(state, ['blockNumber'], payload || 0);
    }

    case AT.CLEAR_PERSONAL: {
      let temp = setIn(state, ['balance'], payload || '0.0');
      temp = setIn(temp, ['staked'], '0.0');
      temp = setIn(temp, ['initialRewardRate'], '0');
      temp = setIn(temp, ['rewards'], '0.0');
      temp = setIn(temp, ['claimedRewards'], '0.0');
      temp = setIn(temp, ['unclaimedRewards'], '0.0');

      return temp;
    }

    case AT.SET_PERSONAL_DATA: {
      let temp = setIn(state, ['balance'], payload?.balance || '0.0');
      temp = setIn(temp, ['staked'], payload?.staked || '0.0');
      temp = setIn(temp, ['initialRewardRate'], payload?.initialRewardRate || '0');
      temp = setIn(temp, ['rewards'], payload?.rewards || '0.0');
      temp = setIn(temp, ['claimedRewards'], payload?.claimedRewards || '0.0');
      temp = setIn(temp, ['unclaimedRewards'], payload?.unclaimedRewards || '0.0');

      return temp;
    }

    default:
      return state;
  }
}
