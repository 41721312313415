import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const clearPersonal = () => ({
  type: AT.CLEAR_PERSONAL,
});
export const setPersonalData = (data) => ({
  type: AT.SET_PERSONAL_DATA,
  payload: data,
});

export const update = () => ({
  type: AT.UPDATE,
});

export const setGlobalData = (data) => ({
  type: AT.SET_GLOBAL_DATA,
  payload: data,
});

export const setCirculatingSupply = (data) => ({
  type: AT.SET_CIRCULATING_SUPPLY,
  payload: data,
});

export const setStakingRate = (data) => ({
  type: AT.SET_STAKING_RATE,
  payload: data,
});

export const setBlockNumber = (data) => ({
  type: AT.SET_BLOCK_NUMBER,
  payload: data,
});

