import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Navigations from "../../../components/Navigations";
import { NavigationLinks } from "../index";



export default function PageDocsRoadmap() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section section-xs filter">
          <NavigationLinks />
        </div>

        <div className="section mt-44">
          <div className="title-neon mb-24">
            Roadmap
          </div>
          <div className="text-md">
            The plans of the ULS Token are for ultimate decentralization and ease of use. When drawing up a roadmap, we will not rely on dates or the exact logic of achieving our goals. We will go to a single result, since it is impossible to predict the result of our actions in advance. But everything that we want to implement is quite simple:
          </div>

          <div className="block-color-16">
            <svg width="540" height="108" viewBox="0 0 540 108" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="54" height="54" transform="matrix(1 0 0 -1 0 108)" fill="white" fillOpacity="0.03" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 54 54)" fill="#01E8DE" fillOpacity="0.1" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 108 108)" fill="white" fillOpacity="0.03" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 162 54)" fill="#01E8DE" fillOpacity="0.1" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 216 108)" fill="white" fillOpacity="0.03" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 270 54)" fill="#01E8DE" fillOpacity="0.1" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 324 108)" fill="white" fillOpacity="0.03" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 378 54)" fill="#01E8DE" fillOpacity="0.1" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 432 108)" fill="white" fillOpacity="0.03" />
              <rect width="54" height="54" transform="matrix(1 0 0 -1 486 54)" fill="#01E8DE" fillOpacity="0.1" />
            </svg>
          </div>


        </div>
        <div className="block mb-20">
          <ul className="roadMap">
            <li>
              Creation of all major smart contracts + connection to the ARBITRUM mainnet, for ease of use and low transaction fees;
            </li>
            <li>
              Airdrop ( needed to create an initial community );
            </li>
            <li>
              Start of sales and full sale of all tokens intended for liquidity;
            </li>
            <li>
              After we recruit a community that is interested in the ULS Token, we will start choosing together which blockchain networks to connect our token to(all voting will take place in our communities or third-party services ) this work will take at least 1 year;
            </li>
            <li>
              We expect to connect at least 10 working blockchain networks + configure bridges to them, and as soon as we implement this, the token contract in the main network will not be updated (by revoking the rights of the OWNER of the address 0x3b7B23c1a883a7038E681948fb5FACa51fc03474). After that, ULS will become a decentralized cryptocurrency, and nothing will be able to affect the code;
            </li>
            <li>
              During development, we will integrate the token into various infrastructures, and in case of interest from other projects and developers, we will leave contacts for communication in this document.(Any interested party can integrate the token and smart contracts independently) we want to integrate the token primarily on exchanges and large crypto wallets + perhaps we will connect inter-network bridges from other teams at the discretion of the community during the possibility of updates);
            </li>
          </ul>
        </div>

        <div className="section text-p">
          <p>
            <div className="text-white title-md title-md-2">
              Final target:
            </div>
          </p>
          <ul>
            <li>
              Decentralized cryptocurrency in different blockchain networks integrated into exchanges, crypto wallets and bridges (with the possibility of staking through a wallet or exchange);
            </li>
            <li>
              Open Community;
            </li>
          </ul>
        </div>

        <Footer />
      </div>
    </main>
  );
}
