import { Contract, } from 'ethers';
import {
  default as getContractData,
} from './getUlsTokenContractData';



export default function getUlsTokenContract(ethersProviderSigner = null) {
  const { address, abi, /*chainId,*/ } = getContractData();
  const contract = new Contract(address, abi, ethersProviderSigner);
  return contract;
}
