import {
  defaultChainId,
  ulsTokenEthereumAddress as ethereumAddress,
  ulsTokenGoerliAddress as goerliAddress,
} from '../configs';
import { UlsTokenAbi as abi } from '../abis';



export default function getUlsTokenContractData() {
  let address = null;
  switch (defaultChainId) {
    case 1: address = ethereumAddress; break;
    case 5: address = goerliAddress; break;
    default: break;
  }

  return {
    address,
    abi,
    chainId: defaultChainId,
  };
}
