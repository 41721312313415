import {
  ulsStartStakingDate,
  k2, decimals,
} from '../configs';
import BN from 'bignumber.js';


BN.set({
  DECIMAL_PLACES: decimals,
  ROUNDING_MODE: BN.ROUND_DOWN,
});


export default function getHalvings() {
  const startTimeMs = new Date(ulsStartStakingDate).getTime();
  const halvings = [{
    startTimeMs: startTimeMs,
    ratePerSec: BN('0.074856664605463500'),
  }];
  let i = 1;
  while (i <= 82) {
    halvings.push({
      startTimeMs: startTimeMs + i * (365 * 24 * 60 * 60 * 1000),
      ratePerSec: BN(halvings[i - 1].ratePerSec.multipliedBy(k2).toFixed(18)),
    });
    i++;
  }

  return { startTimeMs, halvings };
}
