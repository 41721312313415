import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/index.scss';
import './pages/Home/home.scss';

import ReactDOM from 'react-dom/client';
import Providers from './Providers';
import App from './App';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Providers>
    <App />
  </Providers>
);
