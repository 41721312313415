import { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../Header/Header";
import { ReactComponent as Logo1 } from "../../assets/images/icons/logo-1.svg";
import { ReactComponent as Icon1 } from "../../assets/images/icons/icon-1.svg";
import { ReactComponent as Icon2 } from "../../assets/images/icons/icon-2.svg";
import { ReactComponent as Icon3 } from "../../assets/images/icons/icon-3.svg";
import { ReactComponent as Icon4 } from "../../assets/images/icons/icon-4.svg";
import { routerPaths } from '../../router/helper';



export default class Navigations extends PureComponent {
  render() {
    return (
      <div className="navigation">
        <div className="navigation__inner">
          <div className="navigationTop">
            <NavLink to={routerPaths.homePage} className="navigation__logo">
              <Logo1 />
            </NavLink>
            <div className="for-devices"><Header /></div>
          </div>

          <ul>
            <li>
              <NavLink to={routerPaths.stakingPage} activeclassname="active">
                <Icon1 />
                Staking
              </NavLink>
            </li>
            <li>
              <NavLink to={routerPaths.integrationsPage} activeclassname="active">
                <Icon2 />
                Integrations
              </NavLink>
            </li>
            <li>
              <NavLink to={routerPaths.docsPage} activeclassname="active">
                <Icon3 />
                Docs
              </NavLink>
            </li>
            <li>
              <NavLink to={routerPaths.faqPage} activeclassname="active">
                <Icon4 />
                FAQ
              </NavLink>
            </li>
          </ul>

          <div className="block-color-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="202" height="202" viewBox="0 0 202 202" fill="none">
              <rect width="101" height="101" transform="matrix(0 1 1 0 101 0)" fill="#01E8DE" fillOpacity="0.1" />
              <rect width="101" height="101" transform="matrix(0 1 1 0 0 101)" fill="white" fillOpacity="0.06" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
