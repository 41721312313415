import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Navigations from "../../components/Navigations";
//import { NavLink, Link } from 'react-router-dom';
import HomeChart from "../../components/HomeChart/HomeChart";
import Token from '../../assets/images/icons/token.svg';
import TokenDarkImage from '../../assets/images/icons/token-dark.svg';
import { ReactComponent as MoreIcon } from '../../assets/images/icons/more.svg';
import {
  ulsTokenStakingDetailedCalculationUrl,
  ulsTokenUniswapUrl,
  halvings, decimals, defaultChainId,
  startTime,
} from '../../configs';
import { NumericFormat } from 'react-number-format';
import { useState, useEffect } from 'react';

import { useSelector, } from 'react-redux';
import * as S from '../../store/selectors';
//import * as A from '../../store/actions';

import { getExternalLinkProps, getEarnYear } from '../../utils';

import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, parseEther, Signature, verifyTypedData, } from 'ethers';

import { toast } from 'react-toastify';

import {
  getUlsTokenContract,
  getUlsStakingContract,
} from '../../hooks';

import BN from 'bignumber.js';


BN.set({
  DECIMAL_PLACES: decimals,
  ROUNDING_MODE: BN.ROUND_DOWN,
});


function getAPY(P, I) {
  // https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  // P - вложенные средства
  // I - сколько заработал
  // A= P+I
  P = BN(P);
  I = BN(I);
  const A = P.plus(I);

  const d1div365 = 0.00273972602739726027;
  //const p1div365 = new BN(1).dividedBy(365).toNumber();
  // 0.00273972602739726027
  // 2.73972602739726027e-3

  const AdivP = parseFloat(A.dividedBy(P).toString());

  const r = 365 * (Math.pow(AdivP, d1div365) - 1);

  const R = r * 100;

  return R;
}


const timeInitState = {
  days: '0', hours: '00', minutes: '00', seconds: '00',
};

export default function PageHome() {
  const timeNowMs = useSelector((state) => S.clock.getTimeMs(state));
  const { stakingRate, totalStaked, } = useSelector((state) => S.onChainData.getGlobalData(state));

  const {
    balance,
    staked,
    //initialRewardRate,
    rewards,
    unclaimedRewards,
  } = useSelector((state) => S.onChainData.getPersonalData(state));

  const [amount, setAmount] = useState('0');
  const onSelectMax = () => {
    setAmount(balance);
  }
  const onChangeValue = (e) => {
    e.preventDefault();
    let newAmount = BN(e.target.value.trim().toLowerCase());
    if (newAmount.isGreaterThan(balance)) {
      setAmount(balance);
    } else {
      newAmount = newAmount.toString()
      if (Number.isNaN(newAmount) || newAmount === '') {
        setAmount('0');
      } else {
        setAmount(newAmount);
      }
    }
  }

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { address, chainId, isConnected, } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const onStake = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== defaultChainId) {
      if (window.ethereum) {
        return await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else if (isConnected && chainId === defaultChainId && address) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const ulsTokenContract = getUlsTokenContract(signer);
      const ulsStakingContract = getUlsStakingContract(signer);

      // set token value and deadline
      if (parseFloat(amount) === 0) {
        return;
      }
      const value = parseEther(amount);
      const deadline = parseInt(Date.now() / 1000) + 24 * 60 * 60;

      // get the current nonce for the deployer address
      const nonces = await ulsTokenContract.nonces(address);


      // set the domain parameters
      const domain = {
        name: await ulsTokenContract.name(),
        version: "1",
        chainId: `${defaultChainId}`,
        verifyingContract: await ulsTokenContract.getAddress(),
      };

      // set the Permit type parameters
      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      };

      // set the Permit type values
      const values = {
        owner: address,
        spender: await ulsStakingContract.getAddress(),
        value: value,
        nonce: nonces,
        deadline: deadline,
      };

      let sig = {};
      try {
        // sign the Permit type data with the deployer's private key
        const signature = await signer.signTypedData(domain, types, values);

        // split the signature into its components
        sig = Signature.from(signature);

        // verify the Permit type data with the signature
        const recovered = verifyTypedData(
          domain,
          types,
          values,
          sig
        );

      } catch (error) {
        console.error(error);
      }

      try {
        // permit the tokenReceiver address to spend tokens on behalf of the tokenOwner
        let tx = await ulsStakingContract.stakeWithPermit(
          value,
          deadline,
          sig.v,
          sig.r,
          sig.s
        );
        toast.success('Stake pending');
        await tx.wait(1);
        toast.success('Staked');
      } catch (error) {
        console.error(error);
        toast.error('Error');
      }
    }
  }

  const onClaim = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== defaultChainId) {
      if (window.ethereum) {
        return await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else if (isConnected && chainId === defaultChainId && address) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const ulsStakingContract = getUlsStakingContract(signer);

      try {
        const tx = await ulsStakingContract.claimReward();
        toast.success('Claim pending');
        await tx.wait(1);
        toast.success('Claimed');
      } catch (error) {
        console.error(error);
        toast.error('Error');
      }
    }
  }

  const onRestake = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== defaultChainId) {
      if (window.ethereum) {
        return await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else if (isConnected && chainId === defaultChainId && address) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const ulsStakingContract = getUlsStakingContract(signer);


      try {
        const tx = await ulsStakingContract.restake();
        toast.success('Restake pending');
        await tx.wait(1);
        toast.success('Restaked');
      } catch (error) {
        console.error(error);
        toast.error('Error');
      }
    }
  }


  const onUnstake = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== defaultChainId) {
      if (window.ethereum) {
        return await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else if (isConnected && chainId === defaultChainId && address) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const ulsStakingContract = getUlsStakingContract(signer);


      try {
        const tx = await ulsStakingContract.unstake(parseEther(staked));
        toast.success('Unstake pending');
        await tx.wait(1);
        toast.success('Unstaked');
      } catch (error) {
        console.error(error);
        toast.error('Error');
      }
    }
  }


  const [nextHalvingMs, setNextHalving] = useState(0);
  const [time, setTime] = useState(timeInitState);
  const [stakingStartedIn, setStakingStartedIn] = useState(timeInitState);
  useEffect(() => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (nextHalvingMs > timeNowMs) {
      const delta = nextHalvingMs - timeNowMs;
      days = Math.floor(delta / (24 * 60 * 60 * 1000)) % 365;
      hours = Math.floor(delta / (60 * 60 * 1000)) % 24;
      minutes = Math.floor(delta / (60 * 1000)) % 60;
      seconds = Math.floor(delta / (1000)) % 60;
    } else {
      for (let i in halvings) {
        if (halvings[i] > timeNowMs) {
          setNextHalving(halvings[i]);

          const delta = halvings[i] - timeNowMs;
          days = Math.floor(delta / (24 * 60 * 60 * 1000)) % 365;
          hours = Math.floor(delta / (60 * 60 * 1000)) % 24;
          minutes = Math.floor(delta / (60 * 1000)) % 60;
          seconds = Math.floor(delta / (1000)) % 60;
        }
      }
    }

    setTime({
      days: (days).toString(),
      hours: (hours).toString().padStart(2, '0'),
      minutes: (minutes).toString().padStart(2, '0'),
      seconds: (seconds).toString().padStart(2, '0'),
    });

    if (Date.now() < startTime) {
      setStakingStartedIn({
        days: (days).toString(),
        hours: (hours).toString().padStart(2, '0'),
        minutes: (minutes).toString().padStart(2, '0'),
        seconds: (seconds).toString().padStart(2, '0'),
      });
    }
  }, [timeNowMs]);

  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section mt-44">
          <div className="title-neon">
            UNITS LIMITED SUPPLY
          </div>
          <div className="text-md block-1">
            <p>
              The main staking dashboard, where you can stake your tokens and
              receive ULS rewards while monitoring essential on-chain
              information in real-time.
            </p>
            <p>
              Do you have ULS Token?
            </p>
          </div>
          <div className="d-flex">
            <div
              className="button button-color button-2"
              onClick={() => window.open(ulsTokenUniswapUrl, '_blank')}
            >
              Buy ULS Token
            </div>
          </div>
          <div className="section-token" >
            <div className="section-token-inner">
              <img className="for-desktop" src={Token} alt="" />
              <img className="for-devices" src={TokenDarkImage} alt="" />
            </div>
          </div>

          <div className="block-color-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="242" height="243" viewBox="0 0 242 243" fill="none">
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 120.875 0.375)" fill="white" fillOpacity="0.03" />
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 0 121.25)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>
          <div className="block-color-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="213" height="177" viewBox="0 0 213 177" fill="none">
              <rect width="106.5" height="106.5" transform="matrix(0 1 1 0 106.5 0.5)" fill="white" fillOpacity="0.03" />
              <rect width="106.5" height="106.5" transform="matrix(0 1 1 0 0 107)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>
          <div className="block-color-5">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
              <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
            </svg>
          </div>
        </div>


        {Date.now() < startTime
          &&
          <div className="section">
            <div className="row">
              <div className="title-md" style={{ alignItems: 'center!important', }}>
                Staking Started In: {stakingStartedIn.days}d {stakingStartedIn.hours}h {stakingStartedIn.minutes}m {stakingStartedIn.seconds}s
              </div>
            </div>
          </div>
        }
        <div className="d-flex container  position-relative">
          <div className="row">
            <div className="block-color-6">
              <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
                <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
              </svg>
            </div>
            <div className="col-md-6 ">
              <div className="section">
                <div className="block-color-8">
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <rect width="32" height="32" transform="matrix(0 1 1 0 32 0)" fill="white" fillOpacity="0.03" />
                    <rect width="32" height="32" transform="matrix(0 1 1 0 0 32)" fill="white" fillOpacity="0.03" />
                  </svg>
                </div>

                <div className="d-flex  align-items-center justify-content-between">
                  <div className="col-md-6">
                    <div className="title-md">
                      Staking:
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end text-small-m">
                    <span className="text-gray ">
                      Wallet balance:
                    </span>
                    <span className="text-bold pl-4">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={balance}
                        suffix=' ULS'
                      />
                    </span>
                  </div>
                </div>
                <div className="input">
                  <div className="input__control">
                    <input
                      type="number"
                      min={0}
                      max={balance}
                      value={amount}
                      onChange={onChangeValue}
                    />
                    <div className="input__icon">
                      <img src={TokenDarkImage} alt="" />
                      ULS
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end ">
                      <div
                        className="text-select"
                        onClick={onSelectMax}
                      >Select MAX</div>
                    </div>
                  </div>
                </div>

                <div className="block-2 section-md mt-24  ">
                  <div className="row">
                    <div className="col-3">
                      <div className="text-gray">
                        You earn:
                      </div>
                    </div>
                    <div className="col-9 d-flex justify-content-end">
                      <span className="text-bold text-small-m">
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          fixedDecimalScale={true} decimalScale={2}
                          //value={stakingRate}
                          value={((parseFloat(amount)) / (parseFloat(totalStaked) + parseFloat(amount))) * parseFloat(stakingRate)}
                          suffix=' ULS/day'
                        />
                      </span>
                      <span className="text-gray pl-4 text-small-m">
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          fixedDecimalScale={true} decimalScale={2}
                          value={getAPY(
                            amount,
                            getEarnYear(
                              (parseFloat(totalStaked) + parseFloat(amount)),
                              amount
                            )
                          )}
                          prefix='('
                          suffix='% APY)'
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mt-32 d-flex-m gap-12-m">
                  <div className="col-md-6">
                    <div
                      className="button button-color button-3"
                      onClick={onStake}
                    >Stake</div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="button button-border button-3"
                      onClick={onRestake}
                    >Restake</div>
                  </div>
                </div>
                <div className="row mt-32 d-flex-m gap-12-m">
                  <div className="col-md-6">
                    <div
                      className="button button-border button-3"
                      onClick={onClaim}
                    >Claim</div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="button button-border button-3"
                      onClick={onUnstake}
                    >Unstake</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="section">
                <div className="block-color-7">
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <rect width="32" height="32" transform="matrix(0 1 1 0 32 0)" fill="white" fillOpacity="0.03" />
                    <rect width="32" height="32" transform="matrix(0 1 1 0 0 32)" fill="white" fillOpacity="0.03" />
                  </svg>
                </div>

                <div className="row bg-gray mb-2 section-md">
                  <div className="col-md-4 mb-16-m">
                    <div className="text-gray text-xsmall-m mb-4-m">
                      Staking rate:
                    </div>
                    <div className="text text-bold text-small-m">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={stakingRate}
                        suffix=' ULS/day'
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-16-m">
                    <div className="text-gray text-xsmall-m mb-4-m">
                      Staking APY:
                    </div>
                    <div className="text  text-bold text-small-m">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={getAPY(
                          totalStaked,
                          getEarnYear(
                            totalStaked,
                            totalStaked
                          )
                        )}
                        suffix='%'
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-gray text-xsmall-m mb-4-m">
                      Next halving in:
                    </div>
                    <div className="text  text-bold text-small-m">
                      {time.days}d {time.hours}h {time.minutes}m {time.seconds}s
                    </div>
                  </div>
                </div>

                <div className="row bg-gray section-md" style={{ marginTop: '48px', }}>
                  <div className="col-md-4 mb-16-m">
                    <div className="text-gray text-xsmall-m mb-4-m">
                      My rate:
                    </div>
                    <div className="text  text-bold text-small-m">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={(parseFloat(staked) / parseFloat(totalStaked)) * parseFloat(stakingRate)}
                        suffix=' ULS/day'
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-16-m">
                    <div className="text-gray text-xsmall-m mb-4-m">
                      Unclaimed rewards:
                    </div>
                    <div className="text  text-bold text-small-m">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={unclaimedRewards}
                        suffix=' ULS'
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-gray text-xsmall-m mb-4-m">
                      Total rewards:
                    </div>
                    <div className="text  text-bold text-small-m">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={rewards}
                        suffix=' ULS'
                      />
                    </div>
                  </div>
                </div>

                <div className="row bg-gray section-md" style={{ marginTop: '48px', }}>
                  <div className="row mb-12">
                    <div className="col-7 text-gray text-xsmall-m">
                      Total assets in staking:
                    </div>
                    <div className="col-5 d-flex justify-content-end text-bold">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={totalStaked}
                        suffix=' ULS'
                      />
                    </div>
                  </div>

                  <div className="row mb-12 ">
                    <div className="col-7  text-gray d-flex align-items-center gap-1">
                      My assets in staking: <span className="icon"><MoreIcon /></span>
                    </div>
                    <div className="col-5 d-flex justify-content-end text-bold">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={staked}
                        suffix=' ULS'
                      />
                    </div>
                  </div>

                  <div className="row mb-12">
                    <div className="col-7  text-gray d-flex align-items-center gap-1">
                      My pool share: <span className="icon"><MoreIcon /></span>
                    </div>
                    <div className="col-5 d-flex justify-content-end text-bold">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        fixedDecimalScale={true} decimalScale={2}
                        value={(staked / totalStaked) * 100}
                        suffix='%'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section position-relative">
          <div className="block-color-9">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <rect width="32" height="32" transform="matrix(0 1 1 0 32 0)" fill="white" fillOpacity="0.03" />
              <rect width="32" height="32" transform="matrix(0 1 1 0 0 32)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>

          <div className="block-color-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
              <rect x="32.5" y="65" width="32.5" height="32.5" transform="rotate(-90 32.5 65)" fill="white" fillOpacity="0.03" />
              <rect y="32.5" width="32.5" height="32.5" transform="rotate(-90 0 32.5)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>

          <div className="chart">
            <div className="row mb-16">
              <div className="col-9">
                <div className="title-md">Staking Rewards Schedule</div>
              </div>
              <div className="col-3 text-right">
                <a
                  className="text-theme text-decorate-underline text-small-m"
                  {...getExternalLinkProps()}
                  href={ulsTokenStakingDetailedCalculationUrl}
                >Details</a>
              </div>
            </div>
            <HomeChart />
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
}
