import { PureComponent } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, /*Legend,*/ XAxis, YAxis } from 'recharts';
import data from './data.json';



export default class HomeChart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={730} height={250} data={data}
          margin={{ top: 10, right: 30, left: 10, bottom: 0, }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0.046875" stopColor="#1BE0CD" stopOpacity="0.2" />
              <stop offset="1" stopColor="#01BDAB" stopOpacity="0" />
            </linearGradient>
          </defs>
          <XAxis dataKey="Year" />
          <YAxis tickFormat={v => `$${v}`} dataKey="Circulating supply, ULS" />

          <Tooltip />
          {/*<Legend verticalAlign="top" />*/}

          <Area type="monotone" dataKey="Staking rate, ULS/day" stroke="#1BE0CD" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="Rewards, ULS/year" stroke="#1BE0CD" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="Total rewards, ULS" stroke="#1BE0CD" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="Total rewards, %" stroke="#1BE0CD" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="Circulating supply, ULS" stroke="#1BE0CD" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
