import React, { useState, useEffect } from 'react';

import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, } from 'ethers';

import { toast } from 'react-toastify';

import TokenDarkImage from "../../assets/images/icons/token-dark.svg";
//import hero from '../../assets/images/hero.png';
import { NumericFormat } from 'react-number-format';
import { defaultChainId } from '../../configs';

import { useSelector } from 'react-redux';
import * as S from '../../store/selectors';

import {
  getUlsTokenContract,
  getUlsStakingContract,
} from '../../hooks';



function Popup({ priceUsd = '0.0', unclaimedRewards = '0.0', }) {
  const { open: openWeb3Modal, } = useWeb3Modal();
  const { chainId, isConnected, } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();


  const onClaim = async (e) => {
    e.preventDefault();

    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== defaultChainId) {
      if (window.ethereum) {
        return await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const ulsStakingContract = getUlsStakingContract(signer);

      try {
        const tx = await ulsStakingContract.claimReward();
        toast.success('Claim pending');
        await tx.wait(1);
        toast.success('Claimed');
      } catch (error) {
        console.error(error);
        toast.error('Error');
      }
    }
  }

  const onRestake = async (e) => {
    e.preventDefault();

    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== defaultChainId) {
      if (window.ethereum) {
        return await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const ulsStakingContract = getUlsStakingContract(signer);

      try {
        const tx = await ulsStakingContract.restake();
        toast.success('Restake pending');
        await tx.wait(1);
        toast.success('Restaked');
      } catch (error) {
        console.error(error);
        toast.error('Error');
      }
    }
  }

  return (
    <div className="popup">
      <div className="popup-title">
        Total Unclaimed
      </div>
      <div className="popup-number">
        <NumericFormat
          displayType="text"
          thousandSeparator=","
          fixedDecimalScale={true} decimalScale={2}
          value={unclaimedRewards}
        //suffix=' ULS'
        /> <div className="icon-token"> <img src={TokenDarkImage} alt="" /></div>
      </div>

      <div className="popup-price">
        ~ <NumericFormat
          displayType="text"
          thousandSeparator=","
          fixedDecimalScale={true} decimalScale={6}
          value={parseFloat(unclaimedRewards) * parseFloat(priceUsd)}
          prefix='$'
        />
      </div>

      <div
        className="popup-claim button button-border mb-12"
        onClick={onClaim}
      >
        Claim
      </div>
      <div
        className="popup-restake button button-color"
        onClick={onRestake}
      >
        Restake
      </div>
    </div>
  );
}


export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  const {
    priceUsd,
  } = useSelector((state) => S.onChainData.getGlobalData(state));
  const {
    unclaimedRewards,
  } = useSelector((state) => S.onChainData.getPersonalData(state));


  const { open: openWeb3Modal, } = useWeb3Modal();
  const { address, chainId, isConnected, } = useWeb3ModalAccount();


  const [web3ButtonText, setWeb3ButtonText] = useState('Connect');
  useEffect(() => {
    if (isConnected && address && chainId === defaultChainId) {
      const text = address.substring(2, 5) + '...' + address.substring(address.length - 3);
      if (web3ButtonText !== text) {
        setWeb3ButtonText(text);
      }
    } else if (isConnected && chainId !== defaultChainId) {
      if (web3ButtonText !== 'Wrong Network') {
        setWeb3ButtonText('Wrong Network');
      }
    } else {
      if (web3ButtonText !== 'Connect') {
        setWeb3ButtonText('Connect');
      }
    }
  }, [isConnected, address, chainId]);


  const [loading, setLoading] = useState(false);
  async function onConnectWallet() {
    if (loading) return;

    setLoading(true);
    if (isConnected && chainId !== defaultChainId) {
      try {
        //switchNetwork(defaultChainId);
        if (window.ethereum) {
          return await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${defaultChainId.toString(16)}` }],
          });
        }
        const defNetName = defaultChainId === 1 ? 'Ethereum' : 'Goerli'
        return toast.error(`Switch Network to ${defNetName}`);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await openWeb3Modal();
      } catch (error) { }
    }
    setLoading(false);
  }


  return (
    <div className="d-flex justify-content-end gap-12 m-24 gap-8-m">
      <div className="info-att">
        <img src={TokenDarkImage} alt="" />
        <NumericFormat
          displayType="text"
          thousandSeparator=","
          fixedDecimalScale={true} decimalScale={4}
          value={priceUsd}
          prefix='$'
        />
      </div>

      <div className="info-connect">
        <div className="info-connect-content">
          <div className="info-connect-title for-desktop">
            Unclaimed Rewards
          </div>
          <div className="info-connect-text">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              fixedDecimalScale={true} decimalScale={0}
              value={unclaimedRewards}
            //suffix=' ULS'
            />
            <div className="icon-token">  <img src={TokenDarkImage} alt="" /></div>
          </div>
        </div>
        <div
          className={isOpen ? 'info-connect-arrow is-open' : 'info-connect-arrow'}
          onClick={toggle}
        >
        </div>

        {isOpen && <Popup priceUsd={priceUsd} unclaimedRewards={unclaimedRewards} />}
      </div>

      <div
        className="button button-color"
        style={{ backgroundColor: isConnected && chainId !== defaultChainId ? 'red' : undefined, }}
        onClick={onConnectWallet}
      >
        {web3ButtonText}
      </div>

    </div>
  );
}
