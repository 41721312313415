import { useEffect, useState, useContext, useCallback } from 'react';

import ApiContext from './api/api-context';

import { useSelector, useDispatch } from 'react-redux';
import * as S from './store/selectors';
import * as A from './store/actions';

import AppRouter from './router';
import { pause, } from './utils';

//import { getEthersProvider } from './ethers';

import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalState, useWeb3ModalEvents, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, formatUnits, formatEther } from 'ethers';

import {
  getUlsTokenContract,

  getUlsStakingContract,
  //getUlsStakingScheduleContract,
  //getUlsStakingSupplierContract,

  getUlsTokenUniswapV2PairContract,
  getWethTokenUniswapV2PairContract,
} from './hooks';
import {
  getDistributedRewards,
  getStakingRate,
} from './utils';

import {
  defaultChainId, decimals,
  ulsTotalRewards,
} from './configs';


import BN from 'bignumber.js';


BN.set({
  DECIMAL_PLACES: decimals,
  ROUNDING_MODE: BN.ROUND_DOWN,
});



const setupTimer = async (intervalId, dispatch) => {
  const delta = 1000 - Date.now() % 1000;
  await pause(delta);
  intervalId = setInterval(() => {
    dispatch(A.clock.setTimeMs(Date.now()));
  }, 1000);
}

export default function App() {
  const api = useContext(ApiContext);

  const dispatch = useDispatch();
  //const provider = getEthersProvider({ chainId: defaultChainId });
  //console.log(provider);

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open, selectedNetworkId } = useWeb3ModalState();
  const events = useWeb3ModalEvents();
  //console.log('events', events)
  const { walletProvider, walletProviderType } = useWeb3ModalProvider();
  //console.log('walletProviderType', walletProviderType, walletProvider)



  const timeNowMs = useSelector((state) => S.clock.getTimeMs(state));
  const { totalSupply, } = useSelector((state) => S.onChainData.getGlobalData(state));
  const blockNumber = useSelector((state) => S.onChainData.getBlockNumber(state));

  useEffect(() => {
    const time = Date.now();
    const stakingRate = getStakingRate(time);
    dispatch(A.onChainData.setStakingRate(stakingRate));

    //const burnedTokens = BN('10000000').minus(totalSupply).toString();
    const distributedRewards = getDistributedRewards(time);

    const undistributedRewards = BN(ulsTotalRewards).minus(distributedRewards);

    let circulatingSupply = BN(totalSupply).minus(undistributedRewards);
    if (circulatingSupply.isLessThan(0)) {
      circulatingSupply = '0.0';
    } else {
      circulatingSupply = circulatingSupply.toString();
    }

    dispatch(A.onChainData.setCirculatingSupply(circulatingSupply));
  }, [timeNowMs, totalSupply]);


  const updateBlockNumber = useCallback((newBlockNumber) => {
    if (newBlockNumber > blockNumber) {
      dispatch(A.onChainData.setBlockNumber(newBlockNumber));
    }
  }, [blockNumber]);

  useEffect(() => {
    const ethersProviderGlobal = api.getGlobalEthresProvider();
    ethersProviderGlobal.getBlockNumber().then((blockNumber) => {
      dispatch(A.onChainData.setBlockNumber(blockNumber));
    }).catch((error) => console.log(error));

    ethersProviderGlobal.on('block', updateBlockNumber);

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);
    appHeight();

    let intervalId;
    setupTimer(intervalId, dispatch);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', appHeight);
    };
  }, []);


  const [isProcessingOnChainDataGlobal, setProcessingOnChainDataGlobal] = useState(false);
  const updateOnChainDataGlobal = async () => {
    if (isProcessingOnChainDataGlobal) return;


    setProcessingOnChainDataGlobal(true);
    try {
      const ethersProviderGlobal = api.getGlobalEthresProvider();

      const ulsTokenContract = getUlsTokenContract(ethersProviderGlobal);
      const ulsStakingContract = getUlsStakingContract(ethersProviderGlobal);
      //const ulsStakingScheduleContract = getUlsStakingScheduleContract(ethersProviderGlobal);
      //const ulsStakingSupplierContract = getUlsStakingSupplierContract(ethersProviderGlobal);
      const ulsTokenUniswapV2PairContract = getUlsTokenUniswapV2PairContract(ethersProviderGlobal);
      const wethTokenUniswapV2PairContract = getWethTokenUniswapV2PairContract(ethersProviderGlobal);

      // formatUnits, formatEther
      const totalSupply = formatEther(await ulsTokenContract.totalSupply());

      let [totalStaked, historicalRewardRate] = await ulsStakingContract.state();
      totalStaked = formatEther(totalStaked);
      historicalRewardRate = BN(historicalRewardRate).toString();

      let [reserve0_1, reserve1_1] = await ulsTokenUniswapV2PairContract.getReserves();


      let priceEth = '0.0';
      reserve0_1 = formatEther(reserve0_1);
      reserve1_1 = formatEther(reserve1_1);
      // количество эфиров делим на токены
      // 1 uls = ? eth
      if (defaultChainId === 1) {
        // TODO
        // reserve0_1 - uls, reserve1_1 - eth
        priceEth = BN(reserve1_1).dividedBy(reserve0_1).toString();
      } else if (defaultChainId === 5) {
        // reserve0_1 - uls, reserve1_1 - eth
        priceEth = BN(reserve1_1).dividedBy(reserve0_1).toString();
      }


      let [reserve0_2, reserve1_2] = await wethTokenUniswapV2PairContract.getReserves();
      let priceUsd = '0.0';
      let priceEthUsd = '0.0';

      // количество usdt делим на эфиров
      // 1 eth = ? usdt
      if (defaultChainId === 1) {
        // _reserve0 - ETH, reserve1_2 - USDT
        reserve0_2 = formatEther(reserve0_2);
        reserve1_2 = formatUnits(reserve1_2, 6);
        priceEthUsd = BN(reserve1_2).dividedBy(reserve0_2).toString();
        priceUsd = BN(priceEthUsd).multipliedBy(priceEth).toString();
      } else if (defaultChainId === 5) {
        // reserve0_2 - USDT, reserve1_2 - ETH
        reserve0_2 = formatUnits(reserve0_2, 6);
        reserve1_2 = formatEther(reserve1_2);
        priceEthUsd = BN(reserve0_2).dividedBy(reserve1_2).toString();
        priceEthUsd = '2244.0'; // fix
        priceUsd = BN(priceEthUsd).multipliedBy(priceEth).toString();
      }

      const marketCap = BN(totalSupply).multipliedBy(priceUsd).toString();

      dispatch(A.onChainData.setGlobalData({
        timeMs: Date.now(),
        totalSupply,
        totalStaked,
        historicalRewardRate,

        priceEth,
        priceUsd,
        marketCap,
      }));
    } catch (error) {
      console.error(error);
    }
    setProcessingOnChainDataGlobal(false);
  }


  const [isProcessingOnChainDataPersonal, setProcessingOnChainDataPersonal] = useState(false);
  const updateOnChainDataPersonal = async () => {
    if (!isConnected || !address) {
      return;
    }

    if (isProcessingOnChainDataPersonal) return;

    setProcessingOnChainDataPersonal(true);
    try {
      const ethersProviderGlobal = api.getGlobalEthresProvider();
      //const ethersProvider = new BrowserProvider(walletProvider);

      const ulsTokenContract = getUlsTokenContract(ethersProviderGlobal);
      const ulsStakingContract = getUlsStakingContract(ethersProviderGlobal);
      //const ulsStakingScheduleContract = getUlsStakingScheduleContract(ethersProviderGlobal);
      //const ulsStakingSupplierContract = getUlsStakingSupplierContract(ethersProviderGlobal);
      //const ulsTokenUniswapV2PairContract = getUlsTokenUniswapV2PairContract(ethersProviderGlobal);
      //const wethTokenUniswapV2PairContract = getWethTokenUniswapV2PairContract(ethersProviderGlobal);

      const balance = formatEther(await ulsTokenContract.balanceOf(address));

      let [
        staked,
        initialRewardRate,
        rewards,
        claimedRewards,
      ] = await ulsStakingContract.stakers(address);
      staked = formatEther(staked);
      initialRewardRate = BN(initialRewardRate).toString();
      rewards = formatEther(rewards);
      claimedRewards = formatEther(claimedRewards);


      const unclaimedRewards = formatEther(await ulsStakingContract.getRewardView(address));

      // fix
      rewards = BN(claimedRewards).plus(unclaimedRewards).toString();

      dispatch(A.onChainData.setPersonalData({
        balance,
        staked,
        initialRewardRate,
        rewards,
        claimedRewards,
        unclaimedRewards,
      }));
    } catch (error) {
      console.error(error);
    }
    setProcessingOnChainDataPersonal(false);
  }

  useEffect(() => {
    try {
      updateOnChainDataGlobal();
      updateOnChainDataPersonal();
    } catch (error) {
      console.error(error);
    }
  }, [blockNumber]);

  const {
    address: wallet,
  } = useSelector((state) => S.onChainData.getPersonalData(state));
  useEffect(() => {
    if (wallet !== address) {
      dispatch(A.onChainData.clearPersonal(address));
      updateOnChainDataPersonal();
    }
  }, [address, wallet]);

  return (
    <AppRouter />
  );
}
