import { AlchemyProvider } from 'ethers';
import { defaultChainId, getAlchemyApiKey } from '../configs';


let alchemyChainName = null;
if (defaultChainId === 1) alchemyChainName = 'homestead';
if (defaultChainId === 5) alchemyChainName = 'goerli';
if (defaultChainId === 42161) alchemyChainName = 'arbitrum';

const alchemyApiKey = getAlchemyApiKey(defaultChainId);

const alchemyProvider = new AlchemyProvider(alchemyChainName, alchemyApiKey);


export default ({
}) => {
  const getGlobalEthresProvider = () => {
    // https://docs.ethers.org/v5/api/providers/api-providers/#AlchemyProvider
    return alchemyProvider;
  }

  return {
    getGlobalEthresProvider,
  };
}
