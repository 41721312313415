import { createContext } from 'react';
import createApi from './index';
import { apiKey, rootDomain, apiDomain } from '../configs';



const options = {
  domains: { root: rootDomain, api: apiDomain, },
};
const apiOptions = { apiKey, options, };
export let api = createApi({ ...apiOptions, });


const ApiContext = createContext(api);
export default ApiContext;
