import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Navigations from "../../components/Navigations";
import Map from "../../assets/images/intro/map.png";
import Cards from "./Cards";
import './index.scss';



export default function PageIntegrations() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section mt-44">
          <div className="title-neon">
            Integrations
          </div>
          <div className="text-md block-2">
            Process of combining or coordinating different systems, software,
            or technologies to work together as a unified whole
          </div>

          <div className="section-map">
            <div className="section-map-inner">
              <img src={Map} alt="" />
            </div>
          </div>
          <div className="block-color-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="242" height="243" viewBox="0 0 242 243" fill="none">
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 120.875 0.375)" fill="white" fillOpacity="0.03" />
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 0 121.25)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>

          <div className="block-color-5">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
              <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
            </svg>
          </div>
        </div>

        <Cards />

        <Footer />
      </div>
    </main>
  );
}
