import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Navigations from "../../../components/Navigations";
import { NavigationLinks } from "../index";
import TokenDarkImage from "../../../assets/images/icons/token-dark.svg";
import Token from '../../../assets/images/icons/token.svg';
import { Link } from "react-router-dom";
import {
  ulsTokenEthereumAddress, ulsTokenEtherscanUrl,
  ulsTokenArbitrumOneAddress, ulsTokenArbiscanUrl,
} from '../../../configs';
import { getExternalLinkProps } from '../../../utils';



export default function PageDocsUlsToken() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section section-xs filter">
          <NavigationLinks />
        </div>

        <div className="section mt-65 position-relative">
          <div className="row">
            <div className="col-md-6">
              <div className="title-neon mb-24">
                ULS Token
              </div>

              <div className="section-md block-3">
                <div className="block d-flex justify-content-between mb-12">
                  <div className="text-gray">
                    Name:
                  </div>
                  <div className="text-white text-bold">
                    UNITS LIMITED SUPPLY
                  </div>
                </div>

                <div className="block d-flex justify-content-between mb-12">
                  <div className="text-gray">
                    Symbol:
                  </div>
                  <div className="text-white text-bold">
                    ULS <div className="icon-token"> <img src={TokenDarkImage} alt="" /></div>
                  </div>
                </div>

                <div className="block d-flex justify-content-between mb-12">
                  <div className="text-gray">
                    Decimals:
                  </div>
                  <div className="text-white text-bold">
                    18
                  </div>
                </div>

                <div className="block d-flex justify-content-between">
                  <div className="text-gray">
                    Мain network:
                  </div>
                  <div className="text-white text-bold">
                    Ethereum Mainnet
                  </div>
                </div>
              </div>

              <div className="section-md block-3">
                <div className="block mb-16">
                  <div className="title-md mb-12">
                    ULS Address at Ethereum Mainnet:
                  </div>

                  <a
                    className="text-theme d-block text-decorate-underline"
                    title={'ULS Address at Ethereum Mainnet'}
                    {...getExternalLinkProps()}
                    href={ulsTokenEtherscanUrl}
                  >
                    {ulsTokenEthereumAddress}
                  </a>
                </div>

                <p className="text-gray-2 mb-16">
                  For the convenience of the original owners, we connected the Arbitrum mainnet to the ULS Token. This contract is non-updatable and completely decentralized
                </p>

                <div className="block">
                  <div className="title-md mb-12">
                    ULS Address at Arbitrum One Mainnet:
                  </div>

                  <a
                    className="text-theme d-block text-decorate-underline"
                    title={'ULS Address at Arbitrum One Mainnet'}
                    {...getExternalLinkProps()}
                    href={ulsTokenArbiscanUrl}
                  >
                    {ulsTokenArbitrumOneAddress}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 position-relative-d d-flex justify-content-end">
              <div className="section-uls">
                <div className="section-uls-inner">
                  <img src={TokenDarkImage} alt="" />
                  <img src={Token} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row gap-8-m ">
          <div className="col-md-4">
            <div className="box text-center p-32 p-24-m">
              <div className="title-md title-md-2 mb-12 mb-8-m">
                Total Supply:
              </div>
              <div className="text-gray text-bold">
                10,000,000.000000000000000000 ULS <div className="icon-token"> <img src={TokenDarkImage} alt="" /></div>
              </div>
            </div>
          </div> <div className="col-md-4">
            <div className="box text-center p-32 p-24-m">
              <div className="title-md title-md-2 mb-12 mb-8-m">
                Total staking rewards:
              </div>
              <div className="text-gray text-bold">
                6,180,339.887498944824912000 ULS <div className="icon-token"> <img src={TokenDarkImage} alt="" /></div>
              </div>
            </div>
          </div> <div className="col-md-4">
            <div className="box text-center p-32 p-24-m">
              <div className="title-md title-md-2 mb-12 mb-8-m">
                Total Liquidity:
              </div>
              <div className="text-gray text-bold">
                3,819,660.112501055175088000 <div className="icon-token"> <img src={TokenDarkImage} alt="" /></div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-20 ">
          <div className="col-12">
            <div className="text-md text-p">
              <p>
                All subsequent integrations will be carried out after a survey of the community in our social networks! The ULS Token is implemented according to the ERC20 standard on the Ethereum mainnet.
              </p>
              <p>
                This standard was chosen based on usage and convenience statistics. In future updates, we will connect to blockchain networks, that support EVM,they will be selected based on an independent audit, statistics and a survey of holders so that this token has the opportunity to live as long as any of the networks. To date, we consider Ethereum to be the best of all possible blockchain networks. After we pass the stage of connecting additional blockchain networks, the smart contract of the token will not be updated. After creation, a small part of the tokens will be available to holders: 38.19% 3,819,660.112501055175088000 ULS.
              </p>
              <p>
                Other tokens: 61.81% 6,180,339.887498944824912000 ULS we put in a smart contract of staking. The separation is carried out according to the principle of the golden ratio. For more information, see the <Link
                  to="/docs/tokenomics"
                >Tokenomics</Link> section.
              </p>
              <p>
                When using ULS, do not forget about paying transaction fees, to your blockchain network where you use the ULS Token. Any transaction requires gas payment. Never by anyone, new ULS Tokens will not be created.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
}
