import Header from "../../components/Header/index.js";
import Footer from "../../components/Footer/index.js";
import Navigations from "../../components/Navigations/index.js";
import { buttons } from './data.js';
import { NavLink } from 'react-router-dom';



export function NavigationLinks() {
  return (
    <div className="row gx-3">
      {buttons && buttons.map((type, index) => (
        <div key={index} className="col-md-2">
          <NavLink to={type.url} key={index} className="filter-item font-minecraft">
            {type.name}
          </NavLink>
        </div>
      ))}
    </div>
  );
}


export default function PageDocs() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section section-xs filter">
          <div className="row gx-3">
            <NavigationLinks />
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
}
