

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Navigations from "../../components/Navigations";
import Accordion from 'react-bootstrap/Accordion';
//import TokenDarkImage from "../../assets/images/icons/token-dark.svg";
import './index.scss';
import { getExternalLinkProps } from '../../utils';



const dataAccordion = [
  {
    key: 0,
    title: 'What is the ULS Token?',
    text: <>
      <p>ULS - UNITS LIMITED SUPPLY.</p>
      <p>Total Supply = 10,000,000 ULS.</p>
      <p>A new token minting will never be possible by anyone.</p>
      <p>The ULS token is implemented in accordance with the ERC20 standard in the Ethereum Mainnet.</p>
      <p>In the process of development, we will connect additional blockchain networks and the decision on the choice of these networks will take place through a survey in official communities.</p>
      <p>At the initial stage, we connected to the Arbitrium Mainnet for convenience and low transaction fees.</p>
    </>,
  }, {
    key: 1,
    title: 'What is ULS halving and mining?',
    text: <>
      <p>The Staking smart contract is implemented solely on the Ethereum mainnet without any option for updates. The staking function won't expand to new blockchain networks, both presently and in the future.</p>
      <p>Staking rewards are finite, diminishing annually following the principle of the golden ratio through an 'alternative halving.' These rewards are structured over 80 years, precise down to 1-second intervals.</p>
    </>,
  }, {
    key: 2,
    title: 'How long will staking last?',
    text: <>
      <p>The Staking rewards are designed to span 80 years from January 1, 2024, following the principle of the golden ratio through an 'alternative halving.' The reward structure is precise down to 1-second intervals.</p>
    </>,
  }, {
    key: 3,
    title: 'How is security ensured for the user?',
    text: <>
      <p>Staking security is ensured by the Ethereum Mainnet and its consensus mechanism. Further details can be explored on the official Ethereum website (<a
        title='ethereum.org'
        {...getExternalLinkProps()}
        href='https://ethereum.org/'
      >Link</a>). The ULS Token's security follows the same methodology for assurance.</p>
    </>,
  }, {
    key: 4,
    title: 'How will the surveys be conducted?',
    text: <>
      <p>We will exclusively conduct surveys to choose additional blockchain networks and bridges, utilizing social networks for this purpose.</p>
    </>,
  }, {
    key: 5,
    title: 'When will the contract code become immutable?',
    text: <>
      <p>Our goal is to link a minimum of 10 blockchain networks to the ULS Token, ensuring its longevity as long as at least one of these networks exists. Our estimations project this endeavor to span 1-2 years. Additionally, when the community and we jointly decide to render the token non-updatable, we will execute this change.</p>
    </>,
  }, {
    key: 6,
    title: 'Why is ULS needed?',
    text: <>
      <p>ULS stands out as an altcoin that's easily understandable for the average user. Holders have the freedom to use ULS as they see fit.</p>
    </>,
  },
];



export default function PageFaq() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section section-solo mt-44">
          <div className="title-neon mb-24">
            FAQ
          </div>
        </div>

        <div className="section section--faq">
          <Accordion defaultActiveKey="0">
            {dataAccordion.map((item, index) =>
              <Accordion.Item key={index} eventKey={item.key}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>
                  {item.text}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>

        <Footer />
      </div>
    </main>
  );
}
