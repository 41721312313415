import {
  defaultChainId,
  ulsStakingEthereumAddress as ethereumAddress,
  ulsStakingGoerliAddress as goerliAddress,
} from '../configs';
import { UlsStakingAbi as abi } from '../abis';



export default function getUlsStakingContractData() {
  let address = null;
  switch (defaultChainId) {
    case 1: address = ethereumAddress; break;
    case 5: address = goerliAddress; break;
    default: break;
  }

  return {
    address,
    abi,
    chainId: defaultChainId,
  };
}
