export const CLEAR = '@@onChainData/CLEAR';
export const UPDATE = '@@onChainData/UPDATE';

export const SET_GLOBAL_DATA = '@@onChainData/SET_GLOBAL_DATA';
export const SET_CIRCULATING_SUPPLY = '@@onChainData/SET_CIRCULATING_SUPPLY';
export const SET_STAKING_RATE = '@@onChainData/SET_STAKING_RATE';
export const SET_BLOCK_NUMBER = '@@onChainData/SET_BLOCK_NUMBER';

export const CLEAR_PERSONAL = '@@onChainData/CLEAR_PERSONAL';
export const SET_PERSONAL_DATA = '@@onChainData/SET_PERSONAL_DATA';
