import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';



export const isProduction = process?.env?.NODE_ENV === 'production' || false;


export const appName = 'ULS-TOKEN.ORG';
export const appVersion = '0.1.0';


export const apiKey = '?';
export const rootDomain = isProduction
  ? 'https://uls-token.org'
  : `http://${window.location.hostname}:3000`;
export const apiDomain = isProduction
  ? 'https://api.uls-token.org'
  : `http://${window.location.hostname}:3100`;



export const defaultChainId = 1; // TODO goerly - 5, Ethereum - 1


export function getAlchemyApiKey(chainId) {
  switch (chainId) {
    case 1: return 'a3EuhkbPQa9gIvDo6uKTe_p3KI2Qb6Ww';
    case 5: return 'gC7yb91Habf3hwq3c4E10AyNp0BJRc8D';
    default: return null;
  }
}
export function getJsonRpcEndpointUrl(chainId) {
  switch (chainId) {
    case 1: return 'https://eth-mainnet.g.alchemy.com/v2/a3EuhkbPQa9gIvDo6uKTe_p3KI2Qb6Ww';
    case 5: return 'https://eth-goerli.g.alchemy.com/v2/gC7yb91Habf3hwq3c4E10AyNp0BJRc8D';
    default: return null;
  }
}

export function getWsEndpointUrl(chainId) {
  switch (chainId) {
    case 1: return 'wss://eth-mainnet.g.alchemy.com/v2/a3EuhkbPQa9gIvDo6uKTe_p3KI2Qb6Ww';
    case 5: return 'wss://eth-goerli.g.alchemy.com/v2/gC7yb91Habf3hwq3c4E10AyNp0BJRc8D';
    default: return null;
  }
}



// Social Networks
export const ulsCoinmarketcapUrl = 'https://coinmarketcap.com/'; // TODO
export const ulsCoingeckoUrl = 'https://www.coingecko.com/coins/units-limited-supply';
export const ulsDexToolsUrl = 'https://www.dextools.io/app/en/ether/pair-explorer/0x5d4b67e4a2fb15d5f1725b62d8c304a0e78904d5';
export const ulsTeamFinanceUrl = 'https://www.team.finance/view-coin/0x0cdBbc7FE1c8Da0cc41BA96d7EDB4ccE5982F23f?name=UNITS%20LIMITED%20SUPPLY&symbol=ULS&chainid=0x1'


export const ulsKyberswapUrl = 'https://kyberswap.com/swap/ethereum/eth-to-uls';
export const ulsDexscreenerUrl = 'https://dexscreener.com/arbitrum/0x7F1234B036323815574e78Ab5957a8F7Bc29894f';
export const ulsCamelotExchangeUrl = 'https://app.camelot.exchange/?token2=0x0cdBbc7FE1c8Da0cc41BA96d7EDB4ccE5982F23f';
export const ulsPancakeswapUrl = 'https://pancakeswap.finance/swap?chain=arb&outputCurrency=0x0cdBbc7FE1c8Da0cc41BA96d7EDB4ccE5982F23f';

export const uls1inchUrl = 'https://app.1inch.io/#/1/simple/swap/ULS/ETH';
export const uls1inchWalletUrl = 'https://1inch.io/wallet/';
export const ulsOkxWalletUrl = 'https://www.okx.com/web3';
export const ulsTangemWalletUrl = 'https://tangem.com/pricing/?promocode=ULS10';
export const ulsMetamaskWalletUrl = 'https://metamask.io/';
export const ulsUniswapWalletUrl = 'https://wallet.uniswap.org/';
export const ulsRainbowWalletUrl = 'https://rainbow.me/';




export const ulsCryptorankUrl = 'https://cryptorank.io/'; // TODO
export const ulsDropstabUrl = 'https://dropstab.com/coins/units-limited-supply';

export const ulsWebsiteUrl = 'https://uls-token.org';
export const ulsEmailUrl = 'hi@uls-token.org';
export const ulsGithubUrl = 'https://github.com/uls-token';
export const ulsDiscordUrl = 'https://discord.gg/WK4Pbf5bRm';
export const ulsTelegramUrl = 'https://t.me/ulscocommunity';
export const ulsTwitterUrl = 'https://twitter.com/ULS_TOKEN';
export const ulsYoutubeUrl = 'https://youtube.com/@UNITSLIMITEDSUPPLY?si=JHwgYX8CKHCTyEDl';
export const ulsUncxUrl = 'https://univ3.uncx.network/lock/univ3/explore/tokens/chain/42161/address/0x0cdbbc7fe1c8da0cc41ba96d7edb4cce5982f23f';
export const ulsWalletconnectUrl = 'https://explorer.walletconnect.com/uls-token';


export const ulsTokenAuditUrl = 'https://gopluslabs.io/token-security/1/0x0cdbbc7fe1c8da0cc41ba96d7edb4cce5982f23f';
export const whitepaperUrl = '/whitepaper.pdf';
export const ulsTokenStakingDetailedCalculationUrl = 'https://github.com/uls-token/uls-contracts/blob/main/data/staking-by-year.csv';


// Smart Contracts Ethereum Goerli Testnet
export const wethTokenGoerliAddress = '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6';
export const wethTokenGoerliUniswapV2PairAddress = '0x41199A42029871de5c8D956726be316b77fcC253'; // WETH/USDC

export const ulsTokenGoerliAddress = '0x967346a9A269230dB79566Eb4f6442bD13D6E1C2';
export const ulsTokenGoerliEtherscanUrl = `https://goerli.etherscan.io/token/${ulsTokenGoerliAddress}`;
export const ulsTokenGoerliUniswapUrl = `https://app.uniswap.org/swap?outputCurrency=${ulsTokenGoerliAddress}&chain=goerli`;
export const ulsTokenGoerliUniswapV2PairAddress = '0x084BCDFCCD4aefF54Da89bE7A9933E1205888800';



export const ulsStakingGoerliAddress = '0x6cE45A926d163779dad87120EA5ac54e4F503635';
export const ulsStakingGoerliEtherscanUrl = `https://goerli.etherscan.io/address/${ulsStakingGoerliAddress}`;

export const ulsStakingSupplierGoerliAddress = '0xddC1d2f9917eA9DF4E02B91101F24493c78dAC93';
export const ulsStakingSupplierGoerliEtherscanUrl = `https://goerli.etherscan.io/address/${ulsStakingSupplierGoerliAddress}`;

export const ulsStakingScheduleGoerliAddress = '0xB4288B0dFDaF223818a15B438a76dd1F1eE1a1Bd';
export const ulsStakingScheduleGoerliEtherscanUrl = `https://goerli.etherscan.io/address/${ulsStakingScheduleGoerliAddress}`;


// Smart Contracts Arbitrum One Mainnet
export const ulsTokenArbitrumOneAddress = '0x0cdBbc7FE1c8Da0cc41BA96d7EDB4ccE5982F23f';
export const ulsTokenArbiscanUrl = `https://arbiscan.io/token/${ulsTokenArbitrumOneAddress}`;
export const ulsTokenArbitrumOneUniswapUrl = `https://app.uniswap.org/swap?outputCurrency=${ulsTokenArbitrumOneAddress}&chain=arbitrum`;


// Smart Contracts Ethereum Mainnet
export const wethTokenEthereumAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
export const wethTokenEthereumUniswapV2PairAddress = '0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852'; // WETH/USDT


export const ulsTokenEthereumAddress = '0x0cdBbc7FE1c8Da0cc41BA96d7EDB4ccE5982F23f';
export const ulsTokenEtherscanUrl = `https://etherscan.io/token/${ulsTokenEthereumAddress}`;
export const ulsTokenUniswapUrl1 = `https://app.uniswap.org/swap?outputCurrency=${ulsTokenEthereumAddress}&chain=mainnet`;
export const ulsTokenUniswapUrl2 = `https://app.uniswap.org/tokens/ethereum/${ulsTokenEthereumAddress}`;
export const ulsTokenUniswapUrl = ulsTokenUniswapUrl2;
export const ulsTokenEthereumUniswapV2PairAddress = '0x5d4b67E4a2Fb15D5F1725B62d8C304a0e78904D5';

export const ulsStakingEthereumAddress = '0x8fA825416C3558aa7D1B3E94777C0C2610f14528';
export const ulsStakingEtherscanUrl = `https://etherscan.io/address/${ulsStakingEthereumAddress}`;

export const ulsStakingSupplierEthereumAddress = '0xa8de64eB54C4f7Dc122C7D242d84516E8201AFbD';
export const ulsStakingSupplierEtherscanUrl = `https://etherscan.io/address/${ulsStakingSupplierEthereumAddress}`;

export const ulsStakingScheduleEthereumAddress = '0xbBF30313f9e4B56F5FE0737462285595945525f0';
export const ulsStakingScheduleEtherscanUrl = `https://etherscan.io/address/${ulsStakingScheduleEthereumAddress}`;

export const ulsTeamWalletEthereumAddress = '0x3b7B23c1a883a7038E681948fb5FACa51fc03474';
export const ulsTeamWalletEtherscanUrl = `https://etherscan.io/address/${ulsTeamWalletEthereumAddress}`;



export const decimals = 18;
export const ulsTotalRewards = '6180339.887498944824912000';
export const k2 = '0.618033988749894848';

export const ulsStartStakingDateGoerli = '2023-11-11T10:30:00.000Z';
export const ulsStartStakingDateEthereum = '2024-01-01T00:00:00.000Z';
export const ulsStartStakingDate = defaultChainId === 1
  ? ulsStartStakingDateEthereum
  : ulsStartStakingDateGoerli;


export const startTime = new Date(ulsStartStakingDateEthereum).getTime();
export const halvings = [];
let i = 1;
while (i <= 81) {
  halvings.push(
    startTime + i * (365 * 24 * 60 * 60 * 1000)
  );
  i++;
}



// 1. Get projectId at https://cloud.walletconnect.com
export const walletconnectProjectId = '9339b73c25ffcdac09ab44f4cd6eeced';

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: getJsonRpcEndpointUrl(1),
  rpcUrl: 'https://cloudflare-eth.com',
}
const goerli = {
  chainId: 5,
  name: 'Goerli',
  currency: 'goETH',
  explorerUrl: 'https://goerli.etherscan.io',
  rpcUrl: getJsonRpcEndpointUrl(5),
}
const arbitrumOne = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://arb1.arbitrum.io/rpc',
}

export const chainsDefault = [];
if (defaultChainId === 1) chainsDefault.push(mainnet);
if (defaultChainId === 5) chainsDefault.push(goerli);
if (defaultChainId === 42161) chainsDefault.push(arbitrumOne);


// 3. Create modal
export const metadata = {
  name: 'ULS TOKEN',
  description: 'UNITS LIMITED SUPPLY (ULS) TOKEN',
  url: 'https://uls-token.org',
  icons: ['https://avatars.githubusercontent.com/u/139248369'],
};

export const ethersConfig = defaultConfig({
  metadata,
  defaultChainId: defaultChainId,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: getJsonRpcEndpointUrl(defaultChainId), // used for the Coinbase SDK
});

export const web3ModalConfig = {
  ethersConfig,
  chains: chainsDefault,
  defaultChain: chainsDefault[0],

  projectId: walletconnectProjectId,
  termsConditionsUrl: 'https://uls-token.org/#/terms',
  privacyPolicyUrl: 'https://uls-token.org/#/privacy',

  // https://docs.walletconnect.com/web3modal/react/theming
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  },

  /*featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
  ],*/

  // ULS
  tokens: {
    1: {
      address: ulsTokenEthereumAddress,
      //image: '?', //optional
    },
    5: {
      address: ulsTokenGoerliAddress,
      //image: '?', //optional
    },
  },
};

// 3. Create modal
createWeb3Modal(web3ModalConfig);

