import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Navigations from "../../../components/Navigations";
import { NavigationLinks } from "../";



export default function PageDocsIntro() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="for-desktop"><Header /></div>
        <div className="section section-xs filter">
          <NavigationLinks />
        </div>

        <div className="section mt-44">
          <div className="title-neon mb-24">
            Intro
          </div>
          <div className="text-md">
            ULS is a cryptocurrency with a limited issue of the ERС20 standard
            (as we develop, we will connect blockchain network communication,
            with the most secure networks according to statistics,
            focusing primarily on EVM compatibility).
          </div>
          <div className="block-color-14">
            <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="66" height="66" transform="matrix(1 0 0 -1 0 132)" fill="white" fillOpacity="0.03" />
              <rect width="66" height="66" transform="matrix(1 0 0 -1 66 66)" fill="#01E8DE" fillOpacity="0.1" />
            </svg>
          </div>
          <div className="block-color-15">
            <svg width="132" height="86" viewBox="0 0 132 86" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="66" height="66" fill="white" fillOpacity="0.03" />
              <rect x="66" y="66" width="66" height="66" fill="#01E8DE" fillOpacity="0.1" />
            </svg>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="text-md text-p">
              <p>
                And as long as blockchain technology and smart contracts exist,
                this cryptocurrency will live. ULS can exist independently,
                thanks to blockchain technology.
              </p>
              <p>
                Monetary unit = the value invested in it by society.
                And the society itself will decide what value ULS has.
                We will be able to achieve this by implementing a built-in
                staking function ("halving" according to the golden ratio principle)
                in the token, implemented through a series of non-updatable
                smart contracts on the Ethereum mainnet with a direct
                interface via the official website.
              </p>
              <p>
                There are no obligations, no own nodes, no management body, no DAO.
                There is nothing that can affect the code after it
                becomes non- updatable (for more information, see the roadmap).
              </p>
              <p>
                We will create an official community where everyone will have
                the right to vote, no matter how rich you are.
              </p>
              <p>
                Comparing with other projects, we will make it clear.
                With a clear inflationary model, tokenomics, simple "mining"
                and "halving".
              </p>
              <p>
                Without having delusional ideas in personally created games!
                Having no business models.
              </p>
              <p>
                We will the possibility of integration into any cryptospace
                capable of interacting with EVM standards.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
}
