export const routerPaths = {
  homePage: '/',
  stakingPage: '/staking',
  integrationsPage: '/integrations',
  faqPage: '/faq',
  docsPage: '/docs',
  docsIntroPage: '/docs/intro',
  docsUslTokenPage: '/docs/uls-token',
  docsStakingPage: '/docs/staking',
  docsRoadmapPage: '/docs/roadmap',
  docsTokenomicsPage: '/docs/tokenomics',
  docsLinksPage: '/docs/links',

  termsPage: '/terms',
  privacyPage: '/privacy',
};
