import { Contract, } from 'ethers';
import {
  default as getContractData,
} from './getUlsTokenUniswapV2PairContractData';



export default function getUlsTokenUniswapV2PairContract(ethersProviderSigner = null) {
  const { address, abi, /*chainId,*/ } = getContractData();
  const contract = new Contract(address, abi, ethersProviderSigner);
  return contract;
}
