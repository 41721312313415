import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Navigations from "../../../components/Navigations";
import { NavigationLinks } from "../index";

//import Paper from '../../../assets/images/links/paper.svg';
//import Creator from '../../../assets/images/links/creator.svg';
//import Smart from '../../../assets/images/links/smart.png';
import Github from '../../../assets/images/links/github.png';
import Website from '../../../assets/images/links/website.svg';
import Tg from '../../../assets/images/social/tg.svg';
import X from '../../../assets/images/social/x.svg';
import Mail from '../../../assets/images/social/mail.svg';
import Discord from '../../../assets/images/social/discord.svg';
import {
  ulsEmailUrl,
  ulsTwitterUrl,
  ulsDiscordUrl,
  ulsTelegramUrl,
  ulsGithubUrl,
  ulsWebsiteUrl,

  ulsTokenEthereumAddress, ulsTokenEtherscanUrl,
  ulsTokenArbitrumOneAddress, ulsTokenArbiscanUrl,

  ulsStakingEthereumAddress, ulsStakingEtherscanUrl,
  ulsStakingSupplierEthereumAddress, ulsStakingSupplierEtherscanUrl,
  ulsStakingScheduleEthereumAddress, ulsStakingScheduleEtherscanUrl,
} from '../../../configs';
import { getExternalLinkProps } from '../../../utils';



const dataSocial = [
  { link: ulsEmailUrl, title: 'Email', image: Mail, },
  { link: ulsTwitterUrl.replace('https://', ''), title: 'X', image: X, },
  { link: ulsDiscordUrl.replace('https://', ''), title: 'Discord', image: Discord, },
  { link: ulsTelegramUrl.replace('https://', ''), title: 'Telegram', image: Tg, },
  { link: ulsGithubUrl.replace('https://', ''), title: 'GitHub', image: Github, },
  { link: ulsWebsiteUrl.replace('https://', ''), title: 'Website', image: Website, },
];


export default function PageDocsLinks() {
  return (
    <main className="main">
      <Navigations />

      <div className="content flex-grow-1 position-relative">
        <div className="block-color-2">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
            <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
          </svg>
        </div>

        <div className="for-desktop"><Header /></div>
        <div className="section section-xs filter">
          <NavigationLinks />
        </div>


        <div className="section mt-44 section-solo">
          <div className="title-neon mb-24">
            Links
          </div>
          <div className="block-color-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="242" height="243" viewBox="0 0 242 243" fill="none">
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 120.875 0.375)" fill="white" fillOpacity="0.03" />
              <rect width="120.875" height="120.875" transform="matrix(0 1 1 0 0 121.25)" fill="white" fillOpacity="0.03" />
            </svg>
          </div>
          <div className="block-color-5">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="31" y="62" width="31" height="31" transform="rotate(-90 31 62)" fill="white" fillOpacity="0.06" />
              <rect y="31" width="31" height="31" transform="rotate(-90 0 31)" fill="white" fillOpacity="0.06" />
            </svg>
          </div>
        </div>

        <div className="section">
          <div className="title-md title-md-2 mb-16">
            Social Networks
          </div>
          <div className="row g-md-3 gap-4-m">
            {dataSocial.map((item, index) =>
              <div className="col-md-4" key={index}>
                <a
                  className="section-md d-flex justify-content-between"
                  title={item.title}
                  {...getExternalLinkProps()}
                  href={item.title === 'Email'
                    ? `mailto:${item.link}`
                    : `https://${item.link}`
                  }
                >
                  <div className="block">
                    <div className="text-gray text-xsmall-m mb-4">
                      {item.title}
                    </div>
                    <div className="text-bold">
                      {item.link}{" "}
                    </div>
                  </div>

                  <div className="icon-link">
                    {" "}<img src={item.image} alt={item.title} title={item.title} />
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="section">
          <div className="text-p">
            <div className="title-md title-md-2 mb-16">
              ULS Token at Ethereum Mainnet:
            </div>
            <p>
              <a
                title={'ULS Token at Ethereum Mainnet'}
                {...getExternalLinkProps()}
                href={ulsTokenEtherscanUrl}
              >
                {ulsTokenEthereumAddress}
              </a>
            </p>

            <div className="title-md title-md-2 mb-16">
              ULS Token at Arbitrum One Mainnet:
            </div>
            <p>
              <a
                title={'ULS Token at Arbitrum One Mainnet'}
                {...getExternalLinkProps()}
                href={ulsTokenArbiscanUrl}
              >
                {ulsTokenArbitrumOneAddress}
              </a>
            </p>


            <div className="title-md title-md-2 mb-16">
              Staking Smart Contracts:
            </div>
            <p>
              <a
                title={'ULS Token Staking'}
                {...getExternalLinkProps()}
                href={ulsStakingEtherscanUrl}
              >
                {ulsStakingEthereumAddress}
              </a>
              <br />

              <a
                title={'ULS Token Staking Supplier'}
                {...getExternalLinkProps()}
                href={ulsStakingSupplierEtherscanUrl}
              >
                {ulsStakingSupplierEthereumAddress}
              </a>
              <br />

              <a
                title={'ULS Token Staking Schedule'}
                {...getExternalLinkProps()}
                href={ulsStakingScheduleEtherscanUrl}
              >
                {ulsStakingScheduleEthereumAddress}
              </a>
            </p>
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
}
