import { useEffect, useState } from "react";

import { ToastContainer } from 'react-toastify';
import ScrollToTop from './components/ScrollToTop';

import { HashRouter as Router } from 'react-router-dom';

import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store';

import ApiContext from './api/api-context';



const { store, persistor, api, } = configureStore(window?.__PRELOADED_STATE__);
delete window?.__PRELOADED_STATE__;


export default function Providers({ children, }) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  if (!ready) {
    return (null);
  }

  return (
    <>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApiContext.Provider value={api}>
            <Router>
              <ScrollToTop />
              {children}
            </Router>
          </ApiContext.Provider>
        </PersistGate>
      </StoreProvider>

      <ToastContainer
        theme='dark'
        position='top-right'
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        //autoClose={100000}
        //rtl={false}
        //pauseOnFocusLoss
        //pauseOnHover
        //draggable
        style={{
          zIndex: 10000,
        }}
      />
    </>
  );
}