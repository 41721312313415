import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import rehydrate from './rehydrate/reducers';
import clock from './clock/reducers';
import onChainData from './onChainData/reducers';
//import mobileMenu from './mobileMenu/reducers';
//import profile from './profile / reducers';
//import provider from './provider/reducers';



export const createRootReducer = () => {
  const rootReducer = combineReducers({
    rehydrate,
    clock,
    onChainData,
    //mobileMenu,
    //provider,
    //profile: persistReducer({ key: 'profile', storage: storage, stateReconciler: autoMergeLevel1, }, profile),
  });

  return rootReducer;
};

export default createRootReducer;
